import React, { useEffect, useState } from "react";
import collectionsService from "api/collections/collections.service";
import designService from "api/design/design.service";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import DropdownItem from "components/Dropdown/components/DropdownItem";
import { useClickOutside } from "hooks/useClickOutside";
import { IconBookmarkAdded, IconPlus } from "icons";
import CreateNewCollection from "pages/Layout/Header/components/CreateNewCollection";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/commonSlice";

const SaveToCollectionMenu = ({ designId, imageId, show, onClose }: any) => {
  const dispatch = useDispatch();
  const saveCollectionMenuRef = React.useRef<HTMLDivElement>(null);
  const createCollectionMenuRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [showCreateCollectionMenu, setShowCreateCollectionMenu] = useState(false);
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);

  useEffect(() => {
    if (collections.length > 0) setFilteredCollections(collections);
  }, [collections]);

  useClickOutside(containerRef, () => {
    onClose();
  });

  useEffect(() => {
    if (!show) return;
    collectionsService.getCollections({ limit: 0 }).then((res) => setCollections(res.data));
  }, [show]);

  const footer = () => (
    <div className="flex gap-2.5 w-full">
      <Button
        className="btn-secondary w-full gap-[5px]"
        onClick={() => {
          setShowCreateCollectionMenu((prev) => !prev);
        }}
      >
        <IconPlus className="w-5 h-5" />
        Create new Collection
      </Button>
    </div>
  );

  function onSelectCollection(item: any) {
    designService
      .saveToCollection(designId, imageId, {
        design_id: designId,
        image_id: imageId,
        collection_id: item.id,
      })
      .then(() => {
        dispatch(
          setSnackbar({
            icon: IconBookmarkAdded,
            message: " Image successfully saved.",
          })
        );
        onClose();
      });
  }

  function collectionSearch(searchInput: string) {
    if (searchInput === "") setFilteredCollections(collections);
    const lowerCaseSearchInput = searchInput.toLowerCase();
    const _filteredCollections = collections.filter((item: any) => item.name.toLowerCase().includes(lowerCaseSearchInput));
    setFilteredCollections(_filteredCollections);
  }

  return (
    <div ref={containerRef}>
      {showCreateCollectionMenu ? (
        <div ref={createCollectionMenuRef} className=" w-[270px]">
          <CreateNewCollection onClose={onClose} designId={designId} imageId={imageId} />
        </div>
      ) : (
        <div ref={saveCollectionMenuRef} className=" w-[270px]">
          <Dropdown className="rounded-[5px]" Footer={footer} searchCallBack={collectionSearch}>
            {filteredCollections.map((item: any) => (
              <DropdownItem image={item.avatar_path} isSelectable={false} key={item.id} title={item.name} onSelect={() => onSelectCollection(item)} />
            ))}
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default SaveToCollectionMenu;
