import React from "react";
import Button from "components/Button";
import { IconDesign } from "icons";
import { useNavigate } from "react-router-dom";
import Tooltip from "components/Tooltip";

const EditButton = ({ className = "", design_id, text = "", onClick }: any) => {
  const navigate = useNavigate();
  const id = design_id;

  function onOpenEditor() {
    if (onClick) onClick();

    navigate(`/design/${id}`);
  }

  return (
    <Tooltip content="Edit">
      <Button className={className} onClick={onOpenEditor}>
        <IconDesign className="w-4 h-4 flex-shrink-0" /> {text}
      </Button>
    </Tooltip>
  );
};

export default EditButton;
