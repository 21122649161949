import { RefabricProURL } from "../index";
import { ApiResponse } from "../HttpClient";
import { IDesignGetDesignRequest } from "api/design/design.type";

export default {
  async getStyleList(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get("statics/style_list", {});
  },
  async getPatternList(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get("statics/pattern_list", {});
  },
  async getTrainTypes(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get("statics/train_type", {});
  },
  async getTrainStatuses(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get("statics/train_status", {});
  },
  async getFocusTypes(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get("statics/focus_prompts", {});
  },
  async getDiscover(params: IDesignGetDesignRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.get("statics/explorer", { params });
  },
  async getModelList(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get("statics/photoshooting_model_list", {});
  },
};
