import React from "react";
import Modal from "components/Modal";
import { IconClose, IconRefabricCheck } from "icons";
import Button from "components/Button";
import userService from "api/user/user.service";
import useAuthToken from "hooks/useAuthToken";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";

const PaymentSuccessModal = ({ show, onClose, email }: any) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (email)
      userService.refreshToken({ email }).then((res) => {
        useAuthToken.setAuthTokenFromLocalStorage(res.responseData.token);
      });
  }, [email]);

  function handleClose() {
    onClose();
    navigate(PATHS.HOME);
  }

  return (
    <Modal onClose={handleClose} show={show} bodyClassName="border border-grey-light bg-white !max-w-[480px] !w-full p-5">
      <div className="relative flex flex-col items-center gap-10 w-full">
        {email && (
          <span id="email" className="hidden">
            {email}
          </span>
        )}
        <div className="cursor-pointer absolute top-0 left-0 w-fit bg-grey-light rounded-full p-0.5" onClick={onClose}>
          <IconClose />
        </div>
        <IconRefabricCheck />

        <div className="flex flex-col items-center gap-2.5">
          <h3 className="text-h3">Your plan is upgraded!</h3>
          <span className="text-bodyMd">Enjoy Refabric’s features!</span>
        </div>

        <Button className="btn-primary w-full" onClick={handleClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentSuccessModal;
