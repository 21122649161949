import React from "react";
import clsx from "clsx";
import "./Table.css";

export interface ITableHeader {
  key: string;
  text: React.ReactNode;
  render?: (any: any, index?: number) => React.ReactNode;
  renderHeader?: (any: any) => React.ReactNode;
  width?: string;
  align?: string;
  sortValue?: any;
}

export interface ITable {
  headers: ITableHeader[];
  rowElement?: any;
  rowElementProps?: any;
  items: Array<any>;
  footer?: any;
  className?: string;
  loading?: boolean;
  theadClassName?: string;
  theadStyle?: any;
  rowClassName?: string;
  isSelectedRow?: (item: any) => any;
  onClick?: (item: any) => void;
  containerFluidClassName?: string;
  loadingTemplate?: any;
  thClassName?: string;
  afterRow?: any;
  actionButton?: any;
  headerRowClassName?: string;
  tfootClassName?: string;
}

const TableLoading = ({ colSpan, template: Template }: { colSpan: number; template?: any }) => {
  if (Template) {
    return <Template />;
  }

  return (
    <tr>
      <td colSpan={colSpan} className="py-5 px-8 text-center">
        Yükleniyor
      </td>
    </tr>
  );
};

const TableCell = ({ children }: { children: React.ReactNode }) => {
  return <div className="cell text-h6">{children}</div>;
};

const TableRow = ({ children, ...etc }: any) => {
  return <div {...etc}>{children}</div>;
};

const Table = ({
  headers = [],
  items = [],
  className = "",
  loading = false,
  theadClassName,
  theadStyle = {},
  isSelectedRow,
  onClick,
  rowClassName,
  rowElement,
  rowElementProps,
  loadingTemplate,
  containerFluidClassName,
  thClassName,
  afterRow,
  actionButton,
  headerRowClassName,
  tfootClassName,
  ...props
}: ITable) => {
  const _getAfterRow = (k: number, item: any) => {
    if (!afterRow) {
      return false;
    }
    const afterRowContent = afterRow(item);
    if (!afterRowContent) {
      return false;
    }

    return (
      <div key={`afterRow_${k.toString()}`} className={"tr after"}>
        <div className="after-container">{afterRowContent}</div>
      </div>
    );
  };

  const _getHeaders = headers.map((header, i) => (
    <div className={clsx("th", thClassName)} style={{ maxWidth: header.width, minWidth: header.width, justifyContent: header.align }} key={`th_${header.key.toString()}_${i}`}>
      {header.renderHeader ? header.renderHeader(header) : header.text}
    </div>
  ));
  const _getItems = items.map((item, k) => {
    const RowElement = rowElement ?? TableRow;

    return (
      <div className="tr-group" key={`parentRow_${k.toString()}`}>
        {item.beforeRow ? (
          <div className="tr" key={`beforeRow_${k.toString()}`}>
            <td colSpan={headers.length} className="py-5 px-8 text-left">
              {item.beforeRow}
            </td>
          </div>
        ) : (
          <></>
        )}
        <RowElement
          {...(rowElementProps ? rowElementProps(item) : {})}
          onClick={() => {
            if (onClick) {
              onClick(item);
            }
          }}
          className={clsx("tr", rowClassName, isSelectedRow && isSelectedRow(item) ? "active" : "")}
          key={`row_${k.toString()}`}
        >
          {headers.map((header) => {
            const key = `cell_${header.key}_${k.toString()}`;

            return (
              <div className="td" style={{ maxWidth: header.width, minWidth: header.width, justifyContent: header.align }} key={key}>
                {header.render ? header.render(item, k) : <TableCell>{item[header.key]}</TableCell>}
              </div>
            );
          })}
        </RowElement>
        {_getAfterRow(k, item)}
      </div>
    );
  });

  return (
    <div>
      <div className={clsx("table", className)} {...props}>
        <div data-testid="tableHeader" className={clsx("thead", theadClassName)} style={{ ...theadStyle }}>
          <div className={clsx("", containerFluidClassName)}>
            <div className={clsx("tr", headerRowClassName)}>{_getHeaders}</div>
          </div>
        </div>
        {actionButton && actionButton()}
        <div data-testid="tableBody" className={clsx("tbody", containerFluidClassName)}>
          {loading ? <TableLoading template={loadingTemplate} colSpan={headers.length} /> : items.length ? _getItems : <></>}
        </div>
        <div className="">{props.footer && <div className={clsx("tfoot", tfootClassName)}>{props.footer}</div>}</div>
      </div>
    </div>
  );
};

export default Object.assign(Table, {
  Cell: TableCell,
});
