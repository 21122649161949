import React, { useState } from "react";
import { IconBilling, IconChevronDown, IconLogout, IconSettings2, IconShared, IconSupport } from "icons";
import ProgressBar from "components/ProgressBar";
import { useClickOutside } from "hooks/useClickOutside";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";
import { useAppSelector } from "store";
import { EventDispatchLogout } from "router/Router";
import { useDispatch } from "react-redux";
import { setAccountSelectedMenu } from "store/commonSlice";
import { AccountSelectMenu } from "pages/Account/Account";
import userService from "api/user/user.service";

const UserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileMenuRef = React.useRef<HTMLDivElement>(null);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { user } = useAppSelector((state) => state.common);
  const [designCredits, setDesignCredits] = useState({ totalCredit: 0, leftCredit: 0 });
  const [photoshootCredits, setPhotoshootCredits] = useState({ totalCredit: 0, leftCredit: 0 });

  React.useEffect(() => {
    if (!user.id) return;
    if (!showProfileMenu) return;

    userService.getUserDetail({ user_id: user.id }).then((res) => {
      const designCredits = res.responseData?.package_detail?.credits?.created_credit;
      const totalCredit = designCredits.total_credit;
      const leftCredit = designCredits.left_credit;
      setDesignCredits({ totalCredit, leftCredit });

      const photoshootCredits = res.responseData?.package_detail?.credits?.mannequin_credit;
      const totalPhotoshootCredit = photoshootCredits.total_credit;
      const leftPhotoshootCredit = photoshootCredits.left_credit;
      setPhotoshootCredits({ totalCredit: totalPhotoshootCredit, leftCredit: leftPhotoshootCredit });
    });
  }, [showProfileMenu]);

  useClickOutside(profileMenuRef, () => {
    setShowProfileMenu(false);
  });

  const MenuItems = [
    {
      icon: IconSettings2,
      title: "Account Settings",
      onClick: () => {
        dispatch(setAccountSelectedMenu(AccountSelectMenu.AccountSettings));
        navigate(PATHS.ACCOUNT);
      },
    },
    {
      icon: IconShared,
      title: "Manage Members",
      onClick: () => {
        dispatch(setAccountSelectedMenu(AccountSelectMenu.ManageMembers));
        navigate(PATHS.ACCOUNT);
      },
    },
    {
      icon: IconBilling,
      title: "Plans & Billing",
      onClick: () => {
        dispatch(setAccountSelectedMenu(AccountSelectMenu.PlanBilling));
        navigate(PATHS.ACCOUNT);
      },
    },
    {
      icon: IconSupport,
      title: "Support",
      onClick: () => {
        dispatch(setAccountSelectedMenu(AccountSelectMenu.Support));
        navigate(PATHS.ACCOUNT);
      },
    },
    { icon: IconLogout, title: "Logout", onClick: () => window.dispatchEvent(new CustomEvent(EventDispatchLogout)) },
  ];

  return (
    <div className="relative flex w-full">
      <div
        className={clsx("flex w-full justify-between items-center p-2 text-bodyMd text-white border border-bg-3 rounded-[5px]", showProfileMenu ? "pointer-events-none" : "cursor-pointer")}
        onClick={() => setShowProfileMenu((prev) => !prev)}
      >
        <div className="flex items-center gap-2.5 overflow-hidden">
          <div className="flex-center flex-shrink-0 text-h6 w-7 h-7 rounded-full bg-bg-3">{user.name !== "" ? user.name[0] : ""}</div>
          <span className="text-h6 text-ellipsis overflow-hidden">{user?.name}</span>
        </div>
        <IconChevronDown className="w-[18px] h-[18px]" />
      </div>
      {showProfileMenu && (
        <div ref={profileMenuRef} className="absolute top-14 left-0 flex flex-col w-full  rounded-[5px] bg-bg">
          {/* <div className="flex flex-col px-2 py-3 gap-5 border-b border-bg-3"> */}
          <div className="flex flex-col px-2 py-3 gap-4 border-b border-bg-3">
            <span className="text-bodySm text-grey text-ellipsis overflow-hidden">{user?.email}</span>
            <div className="flex flex-col gap-2.5">
              <span className="text-bodySm text-grey">Design Credits</span>
              <ProgressBar maxAmount={designCredits.totalCredit} currentAmount={designCredits.leftCredit} />
              <div className="flex w-full justify-between">
                <span className="text-bodySm text-grey">
                  {designCredits.leftCredit.toLocaleString()}/{designCredits.totalCredit.toLocaleString()}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-2.5">
              <span className="text-bodySm text-grey">Photoshoot Credits</span>
              <ProgressBar progressBarClassName="bg-pink" maxAmount={photoshootCredits.totalCredit} currentAmount={photoshootCredits.leftCredit} />
              <div className="flex w-full justify-between">
                <span className="text-bodySm text-grey">
                  {photoshootCredits.leftCredit.toLocaleString()}/{photoshootCredits.totalCredit.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            {MenuItems.map((item: any, index: number) => (
              <div
                key={`ProfileMenuItem_${index}`}
                className="cursor-pointer flex px-2 py-2.5 gap-2.5 text-menu text-grey hover:text-white last:hover:text-orange"
                onClick={() => {
                  item.onClick();
                  setShowProfileMenu(false);
                }}
              >
                <item.icon className="w-[18px] h-[18px]"></item.icon>
                <span>{item.title}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
