import React, { createContext, useContext, useEffect, useState } from "react";
import collectionsService from "api/collections/collections.service";
import { PATHS } from "router/config/paths";
import { ICollectionEditRequest } from "api/collections/collections.type";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { SortingType } from "pages/TrainingsPage/TrainingsContext";

export interface CollectionsContextType {
  collections: any;
  setCollections: (type: any) => void;
  onDeleteCollection: (collectionId: string) => any;
  onEditCollection: (collection_id: any, data: any) => any;
  likeFilter: boolean;
  setLikeFilter: React.Dispatch<React.SetStateAction<boolean>>;
  sharedFilter: boolean;
  setSharedFilter: React.Dispatch<React.SetStateAction<boolean>>;
  pagination: any;
  isLoading: boolean;
  onChangePagination: (params: any) => void;
  onSharedWithMePage: boolean;
  folders: [];
  setFolders: React.Dispatch<React.SetStateAction<any>>;
  setSearchQuery: (input: string) => void;
  sortingType: SortingType;
  setSortingType: React.Dispatch<React.SetStateAction<any>>;
  count: any;
}

export const CollectionsContext = createContext<CollectionsContextType>({} as any);

const CollectionsProvider = ({ children }: { children: React.ReactNode }) => {
  const { collectionFolders } = useAppSelector((state) => state.common);
  const _defaultPagination = { page: 1, limit: 15 };
  const [pagination, setPagination] = useState<any>(_defaultPagination);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collections, setCollections] = useState<any>([]);
  const [folders, setFolders] = useState<any>(collectionFolders);
  const [likeFilter, setLikeFilter] = useState(false);
  const [sortingType, setSortingType] = useState<SortingType>(SortingType.MostRecent);
  const [sharedFilter, setSharedFilter] = useState<boolean>(false);
  const onSharedWithMePage = window.location.pathname.includes(PATHS.Shared);
  const [searchQuery, setSearchQuery] = useState("");
  const [count, setCount] = useState(0);

  React.useEffect(() => {
    setFolders(collectionFolders);
  }, [collectionFolders]);

  async function fetchCollections() {
    const response = await getCollections(_defaultPagination);
    setCollections(response.data);
  }

  useEffect(() => {
    fetchCollections();
  }, [searchQuery, likeFilter, sortingType]);

  const getCollections = async (params: any) => {
    const _pagination = { page: params.page, limit: params.limit };
    const order = sortingType === SortingType.MostUsed ? "most_popular" : "recently";
    const isLike = likeFilter === true ? 1 : 0;

    try {
      setIsLoading(true);

      const response = await collectionsService.getCollections({
        page: _pagination.page,
        limit: _pagination.limit,
        query: searchQuery,
        order: order,
        is_like: isLike,
      });
      const data = response.data;

      setCount(response?.page?.itemsCount);

      setCollections([...data]);

      setPagination({
        pageNumber: response.page.pageNumber,
        pageCount: response.page.pageCount,
        pageSize: response.page.pageSize,
      });

      return {
        data,
      };
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePagination = async (params: any) => {
    const prevState = collections;
    const loadingElements = new Array(24).fill(1);
    const order = sortingType === SortingType.MostUsed ? "most_popular" : "recently";
    const isLike = likeFilter === true ? 1 : 0;

    if (params.pageNumber > 1) {
      setIsLoading(true);
      setCollections([...prevState, ...loadingElements]);
      try {
        const response = await collectionsService.getCollections({ page: params.pageNumber, limit: params.pageSize, query: searchQuery, order: order, is_like: isLike });

        setCollections([...prevState, ...(response.data as any)]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  async function onDeleteCollection(collectionId: string) {
    return await collectionsService.deleteCollection(collectionId).then(() => setCollections((prev: any) => prev.filter((item: any) => item.id !== collectionId)));

    // return await trainService.deleteTrain(trainId).then(() => fetchTrains());
  }

  async function onEditCollection(collection_id: any, data: ICollectionEditRequest) {
    return collectionsService.editCollection(collection_id, data).then(() => setCollections(collections.map((item: any) => (item.id === collection_id ? { ...item, name: data.name } : item))));
  }

  const contextValue: CollectionsContextType = {
    collections,
    setCollections,
    onDeleteCollection,
    onEditCollection,
    likeFilter,
    setLikeFilter,
    sharedFilter,
    setSharedFilter,
    pagination,
    isLoading,
    onChangePagination,
    onSharedWithMePage,
    folders,
    setFolders,
    setSearchQuery,
    sortingType,
    setSortingType,
    count,
  };

  return <CollectionsContext.Provider value={contextValue}>{children}</CollectionsContext.Provider>;
};

export default CollectionsProvider;

export const useCollectionsContext = () => useContext(CollectionsContext);
