import { RefabricProURL } from "../index";
import { ApiResponse } from "../HttpClient";
import { IStartPayment } from "./payment.type";

export default {
  async getPackageList(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`/payments/package_list`, {});
  },
  async startPayment({ product_id, price_id, seat }: IStartPayment): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`/payments/start/${product_id}/${price_id}`, { params: { seat: seat } });
  },
  async managePortal(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get("/payments/manage_portal", {});
  },
  async getPackageListPhotoshoot(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`/payments/package_list_photoshoot`, {});
  },
};
