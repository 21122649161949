import { createContext, useContext } from "react";

// Define the shape of the context
export interface NewTrainingContextType {
  selectedTrainingType: any;
  setSelectedTrainingType: (type: any) => void;
  handleStartTraining: (data: any) => void;
  trainingTypes: any;
  trainId: string;
  setTrainId: (id: string) => void;
  uploadedImages: any;
  setUploadedImages: (image: any) => void;
  fetchTrainImages: () => void;
  resetAllStates: () => void;
  uploadedFileNames: string[];
  setUploadedFileNames: (arr: any) => void;
  setTrainingTypes: (i: any) => void;
  trainInfo: any;
  setTrainInfo: (data: any) => void;
  trainingClass: any;
  setTrainingClass: (val: any) => void;
  register: any;
  handleSubmit: any;
  setValue: any;
  setError: any;
  clearErrors: any;
  getValues: any;
  errors: any;
  pagination: any;
  onChangePagination: (params: any) => void;
  isLoading: any;
  imageCount: number;
  setSearchQuery: (input: any) => void;
  queueCount: any;
  setQueueCount: (val: any) => void;
}

// Create the context
const NewTrainingContext = createContext<NewTrainingContextType | undefined>(undefined);

export const useNewTrainingContext = () => {
  const context = useContext(NewTrainingContext);
  if (!context) {
    throw new Error("useNewTrainingContext must be used within a ThemeProvider");
  }

  return context;
};

export default NewTrainingContext;
