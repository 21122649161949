import { RefabricProURL } from "../index";
import { ApiResponse } from "../HttpClient";
import { IUtilsImageRemoveBgRequest, IUtilsImageDescribeRequest, IUtilsUploadPatternRequest, IUtilsContactUsRequest, IUtilsUploadFilesRequest } from "./utils.type";

export default {
  async removeBackground(data: IUtilsImageRemoveBgRequest, options?: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`utils/remove_bg`, data, options);
  },
  async describeImage(data: IUtilsImageDescribeRequest, options?: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`utils/describe`, data, options);
  },
  async uploadPattern(data: IUtilsUploadPatternRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`utils/upload_pattern`, data, {});
  },
  async getUploadedPatterns(): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`utils/upload_pattern`, {});
  },
  async contactUs(data: IUtilsContactUsRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`utils/contacts`, data, {});
  },
  async getMagicSelectMasks(image_path: string, options?: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`utils/magic-selection-points`, { image_path: image_path }, options);
  },
  async uploadFiles(data: IUtilsUploadFilesRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`utils/upload_files`, data, {});
  },
  async getUploadedFiles({ file_type, params }: { file_type: string; params?: any }): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`utils/upload_files/${file_type}`, { params });
  },
  async generateUploadUrl({ file_name, content_type }: { file_name: string; content_type?: any }): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`utils/generate_upload_url`, { content_type, file_name });
  },
  async generateUrl({ file_name }: { file_name: string }): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`utils/generate_url`, { params: { file_name } });
  },
};
