import React from "react";
import clsx from "clsx";
import { IconClose } from "icons";
import "./Modal.css";
import { useClickOutside } from "hooks/useClickOutside";

export interface ModalProps {
  className?: string;
  footer?: JSX.Element;
  children: React.ReactNode;
  title?: string;
  onClose: () => void;
  show: boolean;
  bodyClassName?: string;
  modalTitle?: React.ReactNode;
  backdropDisabled?: boolean;
  style?: any;
}

const body = document.querySelector("body");

const Modal = ({ className, children, show, ...etc }: ModalProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  // For reverting body overflow when navigating to another page from a modal (ex. CreateOutOfCreditModal)
  React.useEffect(() => {
    return () => {
      if (body) {
        body.style.overflow = "auto";
      }
    };
  }, []);

  React.useEffect(() => {
    if (!show) return;
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        etc.onClose();
      }
    };

    window.addEventListener("keydown", handleEscape);

    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [show]);

  React.useEffect(() => {
    if (show && body) {
      body.style.overflow = "hidden";
    }
  }, [show, body]);

  useClickOutside(containerRef, () => {
    if (etc.backdropDisabled) return;
    etc.onClose();
  });

  if (!show) {
    return null;
  }

  return (
    <div className={clsx(className, "modalbase")}>
      <div {...etc} ref={containerRef} id="modal" className={clsx("modal", etc.bodyClassName)}>
        <div className="modal-content">{children}</div>
      </div>
      <button className="icon-btn w-10 absolute top-0 -right-[50px] p-2.5 flex-center bg-bg rounded-2xl border border-border" onClick={etc.onClose}>
        <IconClose className="text-white w-3 h-3" />
      </button>
    </div>
  );
};

export default Modal;
