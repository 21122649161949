import React, { useRef, useState } from "react";
import clsx from "clsx";
import Button from "components/Button/Button";
import { IconBookmark } from "icons";
import SaveToCollectionMenu from "components/SaveToCollectionMenu";
import Tooltip from "components/Tooltip";

const Bookmark = ({ isDisplayed, iconClassName = "", designId, imageId, onClick, isImageLandscape, position }: any) => {
  const bookmarkContainerRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  function calculateDistanceToRight(ref: any) {
    if (ref.current) return window.innerWidth - ref.current.getBoundingClientRect().right;

    return 0;
  }

  React.useEffect(() => {
    if (!isDisplayed) setShow(false);
  }, [isDisplayed]);

  function handleOnClick() {
    if (onClick) onClick();
    setShow(true);
  }

  return (
    <div ref={bookmarkContainerRef} className="relative">
      <Tooltip content="Save to Collection" position={position ? position : isImageLandscape ? "top" : "left"}>
        <Button className={clsx("flex-center w-[26px] h-[26px] p-2 hover:bg-green rounded-[3px]", show ? "pointer-events-none bg-green" : "bg-white")} onClick={handleOnClick}>
          <IconBookmark className={clsx(iconClassName, "flex-shrink-0")} />
        </Button>
      </Tooltip>

      {show && (
        <div
          className={clsx("absolute z-50", position === "left" ? "!-left-[275px] top-0" : isImageLandscape ? "top-8 !right-0" : "top-0 right-full")}
          style={{ left: calculateDistanceToRight(bookmarkContainerRef) > 290 ? "calc(100% + 6px)" : "", right: calculateDistanceToRight(bookmarkContainerRef) > 290 ? "" : "calc(100% + 6px)" }}
        >
          <SaveToCollectionMenu designId={designId} imageId={imageId} show={show} onClose={() => setShow(false)} />
        </div>
      )}
    </div>
  );
};

export default Bookmark;
