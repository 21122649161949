import { RefabricProURL } from "../index";
import { ApiResponse } from "../HttpClient";
import {
  IDesignBrushRequest,
  IDesignStartRequest,
  IDesignSaveToCollection,
  IDesignCleanupRequest,
  IDesignGetDesignRequest,
  IZoomOutRequest,
  ICreateVariationRequest,
  IDesignMagic,
  IMultiViewRequest,
  IDownload,
} from "./design.type";

export default {
  async getDesigns(params: IDesignGetDesignRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`designs/`, { params });
  },
  async getDesign(design_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`designs/${design_id}`, {});
  },
  async startDesign(data: IDesignStartRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("designs/start", data, {});
  },
  async deleteImage(design_id: string, image_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.delete(`designs/${design_id}/${image_id}`, {});
  },
  async deleteDesign(design_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.delete(`designs/${design_id}`, {});
  },
  async getDesignStatus(design_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`designs/status/${design_id}`, {});
  },
  async getDesignStatusError(design_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`designs/status_error/${design_id}`, {});
  },
  async getDesignHistory(design_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`designs/${design_id}/history`, {});
  },
  async useBrush(design_id: string, image_id: string, data: IDesignBrushRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`designs/${design_id}/${image_id}/brush`, data, {});
  },
  async useLasso(design_id: string, image_id: string, data: IDesignBrushRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`designs/${design_id}/${image_id}/lasso`, data, {});
  },
  async useCleanup(design_id: string, image_id: string, data: IDesignCleanupRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`designs/${design_id}/${image_id}/cleanup`, data, {});
  },
  async saveToCollection(design_id: string, image_id: string, data: IDesignSaveToCollection): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`designs/${design_id}/${image_id}/add_collection`, data, {});
  },
  async createVariation(design_id: string, image_id: string, data: ICreateVariationRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`designs/${design_id}/${image_id}/create-variation`, data, {});
  },
  async zoomOut(design_id: string, image_id: string, data: IZoomOutRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`designs/${design_id}/${image_id}/zoom-out`, data, {});
  },
  async getDesignArea(design_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`designs/${design_id}/design_area`, {});
  },
  async magicSelect(design_id: string, image_id: string, data: IDesignMagic): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`designs/${design_id}/${image_id}/magic`, data, {});
  },
  async useMultiView(design_id: string, image_id: string, data: IMultiViewRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`designs/${design_id}/${image_id}/create-multiview`, data, {});
  },
  async download(data: IDownload): Promise<any> {
    return await RefabricProURL.post(`designs/${data.design_id}/${data.image_id}/download-high-image`, data, {});
  },
  async startRefine(data: {
    design_type: "refine_hand" | "refine_face";
    seed: "-1";
    cfg_scale: 6.5;
    dimension: "512_768";
    steps: 25;
    set_images: {
      input_image: string;
    };
  }): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("designs/start_refine", data, {});
  },
  async startFlats(data: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("designs/start_flats", data, {});
  },
  async startGetCutout(data: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("designs/start_get_cutout", data, {});
  },
  async startBackview(data: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("designs/start_backview", data, {});
  },
};
