/* eslint-disable no-irregular-whitespace */
import React, { useMemo } from "react";
import "./Header.css";
import clsx from "clsx";
import Menu from "components/Menu";
import CommonHeader from "./components/CommonHeader";
import { PATHS } from "router/config/paths";
import { useDispatch } from "react-redux";
import { showUpdateModal } from "store/commonSlice";

const Header = ({ isFixed }: any) => {
  const dispatch = useDispatch();

  const header = useMemo(() => renderHeader(), [window.location.pathname]);

  function renderHeader() {
    return <CommonHeader isFixed={isFixed} />;
  }

  if (window.location.pathname.includes(PATHS.NEW_TRAINING)) return <></>;

  return (
    <div className="sticky top-0 z-[9990] flex flex-col">
      <span className="text-bodySm text-center text-green bg-[#303326] px-2 py-1.5">
        Discover Our New Photoshooting Hub! Get the Latest Updates and Explore Advanced Photoshooting! 🚀📷{" "}
        <u className="cursor-pointer" onClick={() => dispatch(showUpdateModal())}>
          Learn more
        </u>
      </span>
      <div className={clsx("flex bg-bg-2 h-[56px] items-center w-full border-b border-bg-3")}>
        <Menu isFixed={isFixed} />
        {header}
      </div>
    </div>
  );
};

export default Header;
