import clsx from "clsx";
import React from "react";

const ProgressBar = ({ maxAmount, currentAmount, progressBarClassName }: any) => {
  return (
    <div className="relative h-1 w-full bg-black rounded-full">
      <div className={clsx("h-1 bg-green rounded-full", progressBarClassName)} style={{ width: maxAmount === 0 ? "0px" : (currentAmount / maxAmount) * 100 + "%" }} />
    </div>
  );
};

export default ProgressBar;
