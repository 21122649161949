import { RefabricProURL } from "api";
import { ApiResponse } from "../HttpClient";
import { ICollectionEditRequest, ICollectionsCreate, ICollectionGetCollectionsRequest, ICollectionGetCollectionItemsRequest, ICollectionAddCollectionRequest } from "./collections.type";

export default {
  async getCollections(params: ICollectionGetCollectionsRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`collections/`, { params });
  },
  async getCollection(collection_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`collections/${collection_id}`, {});
  },
  async createCollection(data: ICollectionsCreate): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("collections/", data, {});
  },
  async getCollectionItems(params: ICollectionGetCollectionItemsRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`collections/${params.collection_id}/get_items`, { params });
  },
  async getCollectionImages(params: ICollectionGetCollectionItemsRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`collections/${params.collection_id}/get_images`, { params });
  },
  async addCollection(collection_id: string, data: ICollectionAddCollectionRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`collections/${collection_id}/add_collection`, data, {});
  },
  async deleteCollection(collection_id: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.delete(`collections/${collection_id}`, {});
  },
  async removeImageFromCollection(collection_id: any, image_id: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.delete(`collections/${collection_id}/${image_id}`, {});
  },
  async editCollection(collection_id: any, data: ICollectionEditRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.put(`collections/${collection_id}`, data, {});
  },
};
