import collectionsService from "api/collections/collections.service";
import designService from "api/design/design.service";
import { SortingType } from "pages/TrainingsPage/TrainingsContext";
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { PATHS } from "router/config/paths";

interface IDesignHistoryContext {
  pagination: any;
  designs: any[];
  isLoading: boolean;
  onChangePagination: (params: any) => void;
  onDeleteImage: (id: string, imageId: string, index: number) => void;
  likeFilter: boolean;
  setLikeFilter: React.Dispatch<React.SetStateAction<boolean>>;
  sharedFilter: boolean;
  setSharedFilter: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDesignType: DesignTypes;
  setSelectedDesingType: React.Dispatch<React.SetStateAction<DesignTypes>>;
  sortingType: SortingType;
  setSortingType: React.Dispatch<React.SetStateAction<any>>;
  onSharedWithMePage: boolean;
  setSearchQuery: (input: any) => void;
  count: any;
}

export const DesignHistoryContext = createContext<IDesignHistoryContext>({} as any);

export enum DesignTypes {
  All,
  Design = "design",
  Pattern = "pattern",
}

const DesignHistoryProvider = ({ children, collectionId }: { children: ReactNode; collectionId?: any }) => {
  const [likeFilter, setLikeFilter] = useState<boolean>(false);
  const [sharedFilter, setSharedFilter] = useState<boolean>(false);
  const [designs, setDesigns] = useState<any>([]);
  const _defaultPagination = { page: 1, limit: 15 };
  const [pagination, setPagination] = useState<any>(_defaultPagination);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortingType, setSortingType] = useState<SortingType>(SortingType.MostRecent);
  const [selectedDesignType, setSelectedDesingType] = useState<DesignTypes>(DesignTypes.All);
  const onSharedWithMePage = window.location.pathname.includes(PATHS.Shared);
  const [searchQuery, setSearchQuery] = useState("");
  const [count, setCount] = useState(0);

  async function onDeleteImage(id: string, imageId: string, index: number) {
    if (collectionId) await collectionsService.removeImageFromCollection(collectionId, imageId);
    else await designService.deleteImage(id, imageId);
    setDesigns((prev: any) => prev.filter((_: any, idx: number) => idx !== index));
  }

  async function fetchDesigns() {
    const response = (await getDesigns(_defaultPagination)) as any;
    setDesigns(response.data);
  }

  useEffect(() => {
    fetchDesigns();
  }, [sortingType, selectedDesignType, searchQuery, likeFilter]);

  const getDesigns = async (params: any) => {
    try {
      setIsLoading(true);
      let data;
      let pagination;
      const order = sortingType === SortingType.MostUsed ? "most_popular" : "recently";
      const isLike = likeFilter === true ? 1 : 0;

      if (collectionId === undefined) {
        let reqParams = { page: params.page, limit: params.limit, expanded: 0, order: order, is_like: isLike } as any;
        if (selectedDesignType === DesignTypes.Design || selectedDesignType === DesignTypes.Pattern) reqParams = { ...reqParams, filter: selectedDesignType };
        const response = await designService.getDesigns({ ...reqParams, query: searchQuery });
        setCount(response?.page?.itemsCount);

        data = response.data;
        pagination = response.page;
      } else {
        let reqParams = { collection_id: collectionId } as any;
        if (selectedDesignType === DesignTypes.Design || selectedDesignType === DesignTypes.Pattern) reqParams = { ...reqParams, filter: selectedDesignType };

        const response = await collectionsService.getCollectionImages({ ...reqParams, query: searchQuery });
        setCount(response?.page?.itemsCount);

        data = response.data;
        pagination = response.page;
      }

      setDesigns([...data]);

      setPagination({
        pageNumber: pagination.pageNumber,
        pageCount: pagination.pageCount,
        pageSize: pagination.pageSize,
      });

      return {
        data,
      };
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePagination = async (params: any) => {
    const prevState = designs;
    const loadingElements = new Array(_defaultPagination.limit).fill(1);
    const order = sortingType === SortingType.MostUsed ? "most_popular" : "recently";
    const isLike = likeFilter === true ? 1 : 0;

    if (params.pageNumber > 1) {
      setIsLoading(true);
      setDesigns([...prevState, ...loadingElements]);
      try {
        let data;
        if (collectionId === undefined) {
          let reqParams = { page: params.pageNumber, limit: params.pageSize, expanded: 0, order: order, is_like: isLike } as any;
          if (selectedDesignType === DesignTypes.Design || selectedDesignType === DesignTypes.Pattern) reqParams = { ...reqParams, filter: selectedDesignType };
          const response = await designService.getDesigns(reqParams);

          data = response.data;
        } else {
          let reqParams = { collection_id: collectionId } as any;
          if (selectedDesignType === DesignTypes.Design || selectedDesignType === DesignTypes.Pattern) reqParams = { ...reqParams, filter: selectedDesignType };

          const response = await collectionsService.getCollectionImages({ ...reqParams, query: searchQuery });

          data = response.data;
        }

        setDesigns([...prevState, ...(data as any)]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const contextValue = {
    pagination,
    designs,
    isLoading,
    onChangePagination,
    onDeleteImage,
    likeFilter,
    setLikeFilter,
    sharedFilter,
    setSharedFilter,
    setSelectedDesingType,
    selectedDesignType,
    sortingType,
    onSharedWithMePage,
    setSearchQuery,
    setSortingType,
    count,
  };

  return <DesignHistoryContext.Provider value={contextValue}>{children}</DesignHistoryContext.Provider>;
};

export default DesignHistoryProvider;

export const useDesignHistoryContext = () => useContext(DesignHistoryContext);
