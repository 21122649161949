import React, { useState } from "react";
import DownloadButton from "components/DownloadButton";
import Like from "components/Like";
import EditButton from "components/EditButton/EditButton";
import LazyImg from "components/LazyImg";
import Bookmark from "components/Bookmark";
import ShareButton from "components/ShareButton";
import ShareMenu from "components/ShareMenu";
import Tooltip from "components/Tooltip";
import userService from "api/user/user.service";
import { AnalyticsEvent, removeBackslash } from "utils";
import { useDiscoverContext } from "../DiscoverContext";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

const DiscoverItem = ({ onDeleteImage, data, onClick }: any) => {
  const { onSharedWithMePage } = useDiscoverContext();
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  const [imageError, setImageError] = useState(false);

  function handleImageError() {
    setImageError(true);
  }

  return (
    <div className="relative h-[380px] bg-bg-3 rounded-[3px] border border-bg-3 group" onMouseLeave={() => setIsHovered(false)} onMouseEnter={() => setIsHovered(true)}>
      <ShareMenu show={showShareMenu} onClose={() => setShowShareMenu(false)} />
      <LazyImg
        key={data.path}
        loading="lazy"
        src={data.path}
        className={clsx("cursor-pointer h-full w-full object-cover object-top rounded-[3px]", imageError ? "pointer-events-none" : "pointer-events-auto")}
        draggable={false}
        onClick={onClick}
        onError={handleImageError}
      />

      {!imageError && (
        <div className="absolute hidden flex-col gap-2.5 top-2.5 left-2.5 group-hover:flex z-10">
          <EditButton
            className="btn-primary w-[26px] h-[26px]"
            design_id={data.id}
            onClick={() => {
              if (window.location.pathname === "/") AnalyticsEvent("homepage_design_history_edit", "", "click");
              else AnalyticsEvent("design_history_image_hover_edit", "", "click");
            }}
          />
        </div>
      )}
      {!imageError && (
        <div className="absolute hidden flex-col h-fit gap-1 justify-between top-2 right-2 group-hover:flex z-10">
          <Like
            location={location.pathname === "/" ? "homepage_collections_item" : removeBackslash(location.pathname) + "_item"}
            isLiked={data?.is_liked}
            object_id={data?.image_id}
            object_type={"image"}
            iconClassName="w-4 h-4"
          />
          <Bookmark
            isDisplayed={isHovered}
            imageId={data?.image_id}
            designId={data?.id}
            iconClassName="w-4 h-4"
            onClick={() => {
              if (window.location.pathname === "/") AnalyticsEvent("homepage_design_history_save_collection", "", "click");
              else AnalyticsEvent("design_history_image_hover_save_collection", "", "click");
            }}
          />
          {/* {!onSharedWithMePage && <ShareButton onShowShareMenu={() => setShowShareMenu(true)} />} */}
          {/* <DownloadButton
            isDisplayed={isHovered}
            imageId={data?.image_id}
            designId={data?.id}
            onClick={() => {
              if (window.location.pathname === "/") AnalyticsEvent("homepage_design_history_download", "", "click");
              else AnalyticsEvent("design_history_image_hover_download", "", "click");
            }}
          /> */}
        </div>
      )}
      {data.description && (
        <div
          className="absolute hidden bottom-0 w-full h-full group-hover:flex"
          style={{
            background: "linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 40%)",
          }}
        >
          <div className="mt-auto p-3">
            <span className="text-white text-bodySm">{data.description}</span>
          </div>
        </div>
      )}

      {/* <div className="absolute hidden bottom-2.5 left-2.5 group-hover:flex">
        <Tooltip content="Okan" position={"top"}>
          <div className="flex-center text-white rounded-full w-5 h-5 bg-bg-3 uppercase font-workSans text-[10px] font-medium" style={{ lineHeight: "140%" }}>
            o
          </div>
        </Tooltip>
      </div> */}
      {/* <div className="absolute hidden bottom-2.5 right-2.5 group-hover:flex">{!onSharedWithMePage && <DeleteButton data={data} onDeleteImage={onDeleteImage} />}</div> */}
    </div>
  );
};

export default DiscoverItem;
