import Search from "components/Search/Search";
import React from "react";
import clsx from "clsx";

const Dropdown = ({ children, Footer, className, hiddenSearch = false, searchCallBack, onClose }: any) => {
  function onSearch(searchInput: any) {
    if (searchCallBack) searchCallBack(searchInput);
    else console.log(searchInput);
  }

  return (
    <div className={clsx("flex flex-col w-full max-h-[280px] border border-bg-3 bg-bg-2 rounded-lg", className)}>
      <div className="flex flex-col gap-2.5 overflow-y-auto p-2.5">
        {!hiddenSearch && <Search onSearch={onSearch} />}
        <div className="flex flex-col gap-[5px] overflow-y-auto no-scrollbar">{children}</div>
      </div>
      {Footer && (
        <div className="flex p-2.5 self-end border-t border-bg-3 w-full">
          <Footer onClose={onClose} />
        </div>
      )}
    </div>
  );
};

export default Dropdown;
