import React from "react";
import { IconEdit, IconMoveFile, IconOpen, IconPlus, IconShare, IconTrash, IconUnshare } from "icons";
import clsx from "clsx";
import { useTrainingsContext } from "../TrainingsContext";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";
import { AnalyticsEvent } from "utils";

const ActionMenu = ({ data, isTraining = true, setShowActionMenu, setShowRenameModal, setShowDeleteModal, imageError }: any) => {
  const { onSharedWithMePage } = useTrainingsContext();
  const navigate = useNavigate();
  const MenuItem = ({ icon, title, onClick, hidden }: any) => {
    const Icon = icon;

    return (
      <div
        className={clsx("cursor-pointer  items-center p-2.5 gap-2.5 text-grey text-bodyMd hover:text-white hover:bg-bg-3 rounded-[4px]", hidden ? "hidden" : "flex")}
        onClick={() => {
          setShowActionMenu(false);
          onClick();
        }}
      >
        <Icon className="w-[18px] h-[18px]" />
        {title}
      </div>
    );
  };

  function getDesignType(type: any) {
    switch (type) {
      case 1:
        return "design";
      case 2:
        return "attribute";
      case 3:
        return "pattern";
      default:
        break;
    }
  }

  return (
    <div className="flex flex-col bg-bg rounded-[5px] p-1.5 w-[200px]">
      {data?.status === 2 && (
        <MenuItem
          icon={IconPlus}
          title="Create Design With"
          onClick={() => {
            if (window.location.pathname === "/") AnalyticsEvent("homepage_mytrainings_create_design_with", "", "click");
            else {
              AnalyticsEvent("mytrainings_create_design_with", "", "click");
            }
            navigate("/design", { state: { trainingData: data, designType: getDesignType(data?.group_id) } });
          }}
        />
      )}
      {isTraining && !imageError && (
        <MenuItem
          icon={IconOpen}
          title="Open"
          onClick={() => {
            if (window.location.pathname === "/") AnalyticsEvent("homepage_mytrainings_open", "", "click");
            else AnalyticsEvent("mytrainings_open", "", "click");
            navigate(`/new-training/${data.id}`);
          }}
        />
      )}
      <MenuItem
        hidden={onSharedWithMePage}
        icon={IconEdit}
        title="Rename"
        onClick={() => {
          if (window.location.pathname === "/") AnalyticsEvent("homepage_mytrainings_rename", "", "click");
          else AnalyticsEvent("mytrainings_rename", "", "click");
          setShowRenameModal(true);
        }}
      />
      <MenuItem icon={IconMoveFile} title="Move" onClick={() => console.log("move")} />
      {/* <MenuItem hidden={onSharedWithMePage} icon={IconUnshare} title="Unshare" onClick={() => console.log("unshare")} />
      <MenuItem hidden={onSharedWithMePage} icon={IconShare} title="Share" onClick={() => console.log("share")} /> */}
      <MenuItem
        hidden={onSharedWithMePage}
        icon={IconTrash}
        title="Delete"
        onClick={() => {
          if (window.location.pathname === "/") AnalyticsEvent("homepage_mytrainings_delete", "", "click");
          else AnalyticsEvent("mytrainings_delete", "", "click");
          setShowDeleteModal(true);
        }}
      />
    </div>
  );
};

export default ActionMenu;
