import React, { useState } from "react";
import userService from "api/user/user.service";
import { IconLiked, Iconlike } from "icons";
import { AnalyticsEvent } from "utils";
import { EventLike, EventUnlike } from "utils/events";
interface LikeProps {
  location: string;
  object_type: string;
  object_id: string;
  likedColor?: string;
  unlikedColor?: string;
  iconClassName?: string;
  onClick?: () => void;
  isLiked: boolean;
}
const Like = ({ location, object_type, object_id, likedColor, unlikedColor, onClick, isLiked }: LikeProps) => {
  const [_isLiked, setIsLiked] = useState(isLiked);

  function handleOnClick() {
    if (window.location.pathname === "/") AnalyticsEvent("homepage_collections_like", "", "click");

    if (_isLiked) {
      EventUnlike(location);
    } else {
      EventLike(location);
    }

    userService.like({ object_type, object_id }).then(() => {
      setIsLiked((prev) => !prev);
      if (onClick) onClick();
    });
  }

  return _isLiked ? <IconLiked className={likedColor} onClick={handleOnClick} /> : <Iconlike className={unlikedColor} onClick={handleOnClick} />;
};

export default Like;
