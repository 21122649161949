import utilsService from "api/utils/utils.service";
import { IconWarning } from "icons";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/commonSlice";
import { EventUploadLimitError } from "utils/events";
import { v4 as uuidv4 } from "uuid";

interface IGCSUpload {
  files: any[];
  folderName?: string;
  isLimited?: boolean;
}

interface IGCSSingleUpload {
  file: any;
  folderName?: string;
}

const useGoogleStorage = () => {
  const dispatch = useDispatch();

  async function getSignedUrl(fileName: string, contentType: string) {
    try {
      const response: any = await utilsService.generateUploadUrl({ file_name: fileName, content_type: contentType });

      return { signedUrl: response.upload_url, fileName: response.file_name };
    } catch (error) {
      console.error("Error getting signed URL:", error);
      throw error;
    }
  }

  async function uploadToSignedUrl(url: string, file: Blob, contentType: string) {
    try {
      const binaryData = await file.arrayBuffer();

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": contentType,
        },
        body: binaryData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Upload error response:", errorText);
        throw new Error(`Upload failed: ${errorText}`);
      }

      return response;
    } catch (error) {
      console.error("Error uploading to signed URL:", error);
      throw error;
    }
  }

  async function uploadFiles({ files, folderName = "style_temp/", isLimited = true }: IGCSUpload) {
    const sizeLimit = Number(process.env.REACT_APP_UPLOAD_LIMIT) * 1000000;
    const overSizedFiles = files.filter((file) => file.size > sizeLimit);
    const rightSizedFiles = files.filter((file) => file.size < sizeLimit);

    if (isLimited && overSizedFiles.length > 0) {
      dispatch(
        setSnackbar({
          message: `File size should be less than ${Number(process.env.REACT_APP_UPLOAD_LIMIT)}MB`,
          icon: IconWarning,
        })
      );

      const pathname = window.location.pathname;
      const cleanPathname = pathname.startsWith("/") ? pathname.substring(1) : pathname;
      EventUploadLimitError(cleanPathname);
    }

    try {
      const uploadedFiles = await Promise.all(
        rightSizedFiles.map(async (file: any) => {
          const fileExtension = file?.name.split(".").pop();
          const fileName = `${uuidv4()}.${fileExtension}`;

          const { signedUrl, fileName: _fileName } = await getSignedUrl(fileName, file.type);
          await uploadToSignedUrl(signedUrl, file, file.type);
          const res: any = await utilsService.generateUrl({ file_name: _fileName });

          return {
            Key: fileName,
            Location: res.upload_url,
          };
        })
      );

      return uploadedFiles;
    } catch (error) {
      dispatch(setSnackbar({ message: "Something went wrong uploading files", icon: IconWarning }));

      return [];
    }
  }

  async function uploadFile({ files, folderName = "style_temp/", isLimited = true }: IGCSUpload) {
    const sizeLimit = Number(process.env.REACT_APP_UPLOAD_LIMIT) * 1000000;
    const overSizedFiles = files.filter((file) => file.size > sizeLimit);

    if (isLimited && overSizedFiles.length > 0) {
      dispatch(
        setSnackbar({
          message: `File size should be less than ${Number(process.env.REACT_APP_UPLOAD_LIMIT)}MB`,
          icon: IconWarning,
        })
      );

      const pathname = window.location.pathname;
      const cleanPathname = pathname.startsWith("/") ? pathname.substring(1) : pathname;
      EventUploadLimitError(cleanPathname);

      return [];
    }

    try {
      const fileExtension = files[0]?.name.split(".").pop();
      const fileName = `${uuidv4()}.${fileExtension}`;

      const { signedUrl, fileName: _fileName } = await getSignedUrl(fileName, files[0].type);
      await uploadToSignedUrl(signedUrl, files[0], files[0].type);

      const res: any = await utilsService.generateUrl({ file_name: _fileName });

      return [
        {
          Key: fileName,
          Location: res.upload_url,
        },
      ];
    } catch (error) {
      dispatch(setSnackbar({ message: "Something went wrong uploading the file", icon: IconWarning }));

      return [];
    }
  }

  async function uploadBase64({ file, folderName = "style_temp/" }: IGCSSingleUpload) {
    try {
      const type = file.split(";")[0].split("/")[1];
      const fileName = `canvasItem_${uuidv4()}.${type}`;

      const base64Response = await fetch(file);
      const blob = await base64Response.blob();

      const { signedUrl, fileName: _fileName } = await getSignedUrl(fileName, `image/${type}`);
      await uploadToSignedUrl(signedUrl, blob, `image/${type}`);
      const res: any = await utilsService.generateUrl({ file_name: _fileName });

      return [
        {
          Key: fileName,
          Location: res.upload_url,
        },
      ];
    } catch (error) {
      dispatch(setSnackbar({ message: "Something went wrong uploading the base64 data", icon: IconWarning }));

      return [];
    }
  }

  async function handleUpload({ files, folderName = "style_temp/", isLimited = true }: IGCSUpload) {
    if (files.length === 1) return uploadFile({ files, folderName, isLimited });
    else return uploadFiles({ files, folderName, isLimited });
  }

  return {
    uploadFile,
    uploadFiles,
    handleUpload,
    uploadBase64,
  };
};

export default useGoogleStorage;
