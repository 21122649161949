import React from "react";
import clsx from "clsx";
import { IconPremium } from "icons";

const DropdownItem = ({ isSelected, title, isSelectable = true, onSelect, onRemove, image, disabled, isPremium = false }: any) => {
  const BoxNotSelected = () => <div className="h-2.5 w-2.5 rounded-[2px] bg-bg-3"></div>;
  const BoxSelected = () => <div className="h-2.5 w-2.5 rounded-[2px] bg-green"></div>;

  return (
    <div
      className={clsx(
        "flex cursor-pointer border border-bg-3 rounded-[3px] items-center px-2.5 py-3 gap-2.5 group/dropdownItemCotainer hover:bg-green hover:bg-opacity-10 hover:border-opacity-0",
        disabled ? "pointer-events-none" : "",
        isSelected ? "bg-green bg-opacity-10" : "",
        isPremium ? "bg-bg opacity-40" : ""
      )}
      onClick={() => {
        if (isSelectable) {
          if (!isSelected) onSelect();
          else onRemove();
        } else onSelect();
      }}
    >
      {image && <img className="w-10 h-10 object-cover object-center rounded-[2px]" src={image} />}
      {isSelectable && (isSelected ? <BoxSelected /> : <BoxNotSelected />)}
      <span className={clsx("text-bodySm group-hover/dropdownItemCotainer:text-green text-white", isSelected ? "text-green" : "text-white")}>{title}</span>
      {isPremium && <IconPremium className="text-green h-3.5 w-3.5 ml-auto" />}
    </div>
  );
};

export default DropdownItem;
