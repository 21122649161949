import DownloadButton from "components/DownloadButton";
import Modal from "components/Modal";
import React from "react";

const ImagePopup = ({ image, show, onClose }: any) => {
  React.useEffect(() => {
    const handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, []);

  return (
    <Modal className="!z-[9999] !bg-opacity-70" bodyClassName="!border-none !rounded-none flex h-full py-12" onClose={onClose} show={show}>
      <div className="flex h-full w-fit p-5 border-[3px] border-bg rounded-lg bg-bg-2">
        <div className="flex h-full gap-2.5">
          <img src={image} className="object-contain h-full rounded-[10px] max-w-[80vw] border border-bg" draggable={false} />
          <div className="h-fit">
            <DownloadButton imageURL={image} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImagePopup;
