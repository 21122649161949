import React, { useState } from "react";
import { IconChevronDown } from "icons";
import clsx from "clsx";
import { useClickOutside } from "../../hooks/useClickOutside";

export interface ISelectOption {
  id: any;
  title: any;
  value?: any;
}

interface ISelect {
  options: ISelectOption[];
  onChange: (value: ISelectOption) => void;
  direction?: string;
  className?: string;
  titlePrefix?: string;
  defaultId?: number;
  disabled?: boolean;
  isLightMode?: boolean;
  dropdownClassName?: string;
}

const Select = ({ titlePrefix = "", defaultId, options, onChange, direction = "bottom", className, disabled, isLightMode = false, dropdownClassName }: ISelect) => {
  const [show, setShow] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState<ISelectOption>(options.find((item) => item.id === defaultId) ?? options[0]);
  const listRef = React.useRef<HTMLUListElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const isDirectionTop = _isDirectionTop() ? true : direction === "top";

  function calculateDistanceToBottom(ref: any) {
    if (ref.current) return window.innerHeight - ref.current.getBoundingClientRect().bottom;

    return 0;
  }

  function _isDirectionTop() {
    if (listRef.current) return calculateDistanceToBottom(containerRef) - listRef.current?.clientHeight < 70; //62 Create butonun height i 8 i de bosluk olsun diye ekledim
  }

  React.useEffect(() => {
    setSelectedItem(options.find((item) => item.id === defaultId) ?? options[0]);
  }, [defaultId, options]);

  const onToggle = () => {
    if (disabled) return;

    setShow(!show);
  };
  const onSelect = (option: ISelectOption) => {
    setSelectedItem(option);
    onChange(option);
    onToggle();
  };
  useClickOutside(containerRef, () => {
    setShow(false);
  });

  return (
    <div className="flex-1 relative" ref={containerRef}>
      <div className={clsx("cursor-pointer flex w-full max-w-[160px] h-[38px] text-white border border-bg-3 rounded-[5px]", disabled ? "pointer-events-none" : "", className)} onClick={onToggle}>
        <span className="text-bodySm text-overflow py-2.5 px-2.5">{titlePrefix + (selectedItem?.title ?? "")}</span>
        <div className={clsx("py-2.5 px-2.5 border-l border-bg-3 rounded-r-[4px]", isLightMode ? "bg-white" : "bg-bg")}>
          <IconChevronDown className="w-[18px] h-[18px]" />
        </div>
      </div>
      <div
        ref={dropdownRef}
        className={clsx(
          "absolute z-50 transition-all duration-200 min-w-full overflow-hidden",
          isLightMode ? "text-black" : "text-white",
          isDirectionTop ? "-translate-y-full" : "translate-y-full",
          show ? (isDirectionTop ? "-top-2" : "-bottom-2") : isDirectionTop ? "top-0" : "bottom-0"
        )}
        style={{ height: show ? `${(listRef.current?.scrollHeight as number) + 2}px` : 0 }} // +2 for borders
      >
        <ul className={clsx("flex flex-col p-3 gap-[5px] bg-bg-2 rounded-[5px] border border-bg-3", isLightMode ? "bg-white" : "bg-bg-2", dropdownClassName)} ref={listRef}>
          {options.map((option: any, index) => {
            return (
              <li
                key={index}
                className={clsx(
                  "flex flex-col gap-[3px] text-bodySm py-3 px-2.5 rounded-[3px] border border-bg-3 cursor-pointer",
                  selectedItem?.id === option?.id ? (isLightMode ? "text-black bg-green bg-opacity-50" : "border-none bg-green bg-opacity-10 text-green") : ""
                )}
                onClick={() => onSelect(option)}
              >
                {option.title}
                <span className="text-[11px] text-grey">{option?.description}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Select;
