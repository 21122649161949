import React from "react";
import Button from "components/Button";
import Modal from "components/Modal";
import { IconClose, IconRefabricFail } from "icons";

const PaymentFailModal = ({ show, onClose }: any) => {
  return (
    <Modal onClose={onClose} show={show} bodyClassName="border border-grey-light bg-white !max-w-[480px] !w-full p-5">
      <div className="relative flex flex-col items-center gap-10 w-full">
        <div className="cursor-pointer absolute top-0 left-0 w-fit bg-grey-light rounded-full p-0.5" onClick={onClose}>
          <IconClose />
        </div>
        <IconRefabricFail className="mt-2.5" />

        <div className="flex flex-col items-center gap-2.5">
          <h3 className="text-h3">Sorry, payment failed!</h3>
          <span className="text-bodyMd text-center">There was an error processing your payment. Please try another payment method or contact us if the problem insists.</span>
        </div>

        <Button className="btn-primary w-full">Close</Button>
      </div>
    </Modal>
  );
};

export default PaymentFailModal;
