import React, { useState } from "react";
import { IconAttribute, IconMore, IconPattern, IconShared, IconStyle } from "icons";
import { ImageDefault } from "images";
import clsx from "clsx";
import ActionMenu from "./ActionMenu";
import { useClickOutside } from "hooks/useClickOutside";
import Like from "./Like";
import DeleteModal from "components/DeleteModal";
import RenameModal from "components/RenameModal";
import LazyImg from "components/LazyImg";
import Tooltip from "components/Tooltip";
import userService from "api/user/user.service";
import { useCollectionsContext } from "../CollectionsContext";
import { ICollectionEditRequest } from "api/collections/collections.type";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";
import { useDrag, useDrop } from "react-dnd";
import collectionsService from "api/collections/collections.service";
import { AnalyticsEvent, removeBackslash } from "utils";

export const ItemTypes = {
  COLLECTION: "collection",
};

const CollectionsItem = ({ data, onDeleteCollection, onEditCollection }: any) => {
  const { setFolders, setCollections } = useCollectionsContext();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [imageError, setImageError] = useState(false);

  function handleImageError() {
    setImageError(true);
  }

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.COLLECTION,
    item: { id: data.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.COLLECTION,
      drop: (_item: any) => {
        if (data.id === _item.id) return;
        collectionsService
          .addCollection(data.id, {
            in_collection_id: _item.id,
          })
          .then(() => setCollections((prev: any) => prev.filter((item: any) => item.id !== _item.id)));
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    []
  );

  // React.useEffect(() => {
  //   collectionsService.getCollectionItems({
  //     collection_id: data.id,
  //   });
  // }, []);

  useClickOutside(containerRef, () => {
    setShowActionMenu(false);
  });

  function renderIcon() {
    switch (data?.group_id) {
      case 1:
        return <IconStyle className="flex-shrink-0 text-green w-[18px] h-[18px]" />;
      case 2:
        return <IconAttribute className="flex-shrink-0 text-purple w-[18px] h-[18px]" />;
      case 3:
        return <IconPattern className="flex-shrink-0 text-pink w-[18px] h-[18px]" />;
      default:
        break;
    }
  }

  return (
    <div ref={drop} className="flex gap-3 p-3 border border-border rounded-md w-full">
      <RenameModal
        name={data?.name}
        trainId={data?.id}
        onClose={() => setShowRenameModal(false)}
        show={showRenameModal}
        onEdit={(newData: ICollectionEditRequest) => onEditCollection(data.id, { name: newData, directory_id: data.directory_id, avatar_path: "" })}
      />
      <DeleteModal
        trainId={data?.id}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        show={showDeleteModal}
        onDelete={onDeleteCollection}
      />
      <div className="relative flex flex-col gap-3 rounded-md w-full" ref={drag}>
        <div className="relative flex items-center gap-[5px]">
          {renderIcon()}
          <div className="flex items-center gap-[5px] w-full">
            <span className="text-white text-h6 custom-line-clamp-1">{data?.name}</span>
            {/* <Tooltip content="From Tugberk">
              <IconShared className="w-4 h-4 text-grey" />
            </Tooltip> */}
          </div>
          <Like
            location={location.pathname === "/" ? "homepage_collections_item" : removeBackslash(location.pathname) + "_item"}
            isLiked={data?.is_liked}
            object_id={String(data.id)}
            object_type="collection"
            likedColor="cursor-pointer flex-shrink-0 text-white w-[18px] h-[18px]"
            unlikedColor="cursor-pointer flex-shrink-0 text-grey w-[18px] h-[18px]"
          />
          <IconMore className={clsx("cursor-pointer flex-shrink-0 w-[18px] h-[18px] text-grey", showActionMenu ? "pointer-events-none" : "")} onClick={() => setShowActionMenu(true)} />
          {showActionMenu && (
            <div ref={containerRef} className="absolute top-5 right-0 z-50">
              <ActionMenu data={data} setShowActionMenu={setShowActionMenu} setShowRenameModal={setShowRenameModal} setShowDeleteModal={setShowDeleteModal} imageError={imageError} />
            </div>
          )}
        </div>
        <div
          className={clsx("cursor-pointer relative h-full aspect-square overflow-hidden rounded-[3px]", imageError ? "pointer-events-none" : "pointer-events-auto")}
          onClick={() => {
            if (window.location.pathname === "/") AnalyticsEvent("homepage_collection_item_on_click", "", "click");
            else AnalyticsEvent("mycollections_collection_item_on_click", "", "click");

            navigate(`/collections/${data.id}`);
          }}
        >
          <LazyImg
            src={data?.avatar_path !== "" ? data?.avatar_path : ImageDefault}
            draggable={false}
            className="aspect-square object-cover object-center overflow-hidden"
            onError={handleImageError}
          />
        </div>
      </div>
    </div>
  );
};

export default CollectionsItem;
