import React from "react";
import ErrorModal from "./ErrorModal";
import SnackbarProvider from "components/Snackbar/SnackbarProvider";
import Header from "./Header";
import { useAppSelector } from "store";
import { useIsMobile } from "hooks/useIsMobile";
import { useIsTablet } from "hooks/useIsTablet";
import TutorialItem from "components/Tutorial/components/TutorialItem";
import WarningSnackbar from "components/WarningSnackbar";
import UpdateModal from "components/UpdateModal";
import MaintenanceModal from "components/MaintenanceModal";

interface Props {
  children: React.ReactNode;

  [key: string]: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Layout = ({ children, ...etc }: Props) => {
  const { isMainMenuFixed } = useAppSelector((state) => state.common);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return (
    <main
      className="bg-black flex flex-col"
      style={{
        minHeight: "100dvh",
      }}
    >
      {!etc.hiddenHeader && <Header isFixed={isMobile || isTablet ? false : etc.isSideMenuFixed} />}
      <SnackbarProvider />
      <WarningSnackbar />
      <UpdateModal />
      <TutorialItem />
      {/* <MaintenanceModal /> */}
      <div className="flex flex-1">
        {isMobile || isTablet ? <></> : (!etc.hiddenHeader && isMainMenuFixed) || etc.isSideMenuFixed ? <div className="flex flex-shrink-0 w-[220px]" /> : <></>}
        {children}
      </div>
      <ErrorModal />
    </main>
  );
};

export default Layout;
