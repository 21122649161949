import React, { createContext, useContext, useEffect, useState } from "react";
import trainService from "api/train/train.service";
import staticsService from "api/statics/statics.service";
import { ITrainEditRequest } from "api/train/train.type";
import { PATHS } from "router/config/paths";

interface TrainingsProviderProps {
  children: React.ReactNode;
}

export enum TrainingTypes {
  All,
  Style,
  Attribute,
  Pattern,
  Mood,
}

export enum TrainingStatus {
  Draft,
  Start,
  End,
  All,
}

export enum SortingType {
  MostRecent,
  MostUsed,
}

export interface TrainingsContextType {
  selectedTrainingType: any;
  setSelectedTrainingType: (type: any) => void;
  selectedTrainStatus: any;
  setSelectedTrainStatus: (type: any) => void;
  trainStatuses: any;
  setTrainStatuses: (type: any) => void;
  trainingTypes: any;
  setTrainingTypes: (type: any) => void;
  selectedAttributes: any;
  setSelectedAttributes: (type: any) => void;
  trainings: any;
  setTrainings: (type: any) => void;
  onDeleteTrain: (trainId: string) => any;
  onStartTrain: (trainId: string) => any;
  onEditTrain: (train_id: string, data: ITrainEditRequest) => any;
  setSortingType: (data: any) => void;
  pagination: any;
  isLoading: boolean;
  onChangePagination: (params: any) => void;
  likeFilter: boolean;
  setLikeFilter: React.Dispatch<React.SetStateAction<boolean>>;
  sharedFilter: boolean;
  setSharedFilter: React.Dispatch<React.SetStateAction<boolean>>;
  count: number;
  onSharedWithMePage: boolean;
  setSearchQuery: (input: any) => void;
  sortingType: SortingType;
}

const TrainingsContext = createContext<TrainingsContextType>({} as any);

const TrainingsProvider: React.FC<TrainingsProviderProps> = ({ children }) => {
  const _defaultPagination = { page: 1, limit: 15 };
  const [pagination, setPagination] = useState<any>(_defaultPagination);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [likeFilter, setLikeFilter] = useState<boolean>(false);
  const [sharedFilter, setSharedFilter] = useState<boolean>(false);
  const [trainingTypes, setTrainingTypes] = useState<any>([]);
  const [selectedTrainingType, setSelectedTrainingType] = useState<TrainingTypes>(TrainingTypes.All);
  const [trainStatuses, setTrainStatuses] = useState<any>([]);
  const [selectedTrainStatus, setSelectedTrainStatus] = useState<TrainingStatus>(TrainingStatus.All);
  const [selectedAttributes, setSelectedAttributes] = useState<any>([]);
  const [trainings, setTrainings] = useState<any>([]);
  const [sortingType, setSortingType] = useState<SortingType>(SortingType.MostRecent);
  const [count, setCount] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const onSharedWithMePage = window.location.pathname.includes(PATHS.Shared);

  async function fetchTrains() {
    const response = await getTrainings(_defaultPagination);
    setTrainings(response.data);
  }
  useEffect(() => {
    // staticsService.getTrainTypes().then((res) => setTrainingTypes(res.responseData));
    staticsService.getTrainStatuses().then((res: any) => setTrainStatuses(res.responseData));
  }, []);

  useEffect(() => {
    fetchTrains();
  }, [sortingType, searchQuery, likeFilter]);

  const getTrainings = async (params: any) => {
    const _pagination = { page: params.page, limit: params.limit };
    const order = sortingType === SortingType.MostUsed ? "most_popular" : "recently";
    const isLike = likeFilter === true ? 1 : 0;

    try {
      setIsLoading(true);

      const response = await trainService.getCreatedTrains({
        page: _pagination.page,
        limit: _pagination.limit,
        query: searchQuery,
        order: order,
        is_like: isLike,
      });
      const data = response.data;

      setTrainings([...data]);

      setCount(response?.page?.itemsCount);

      setPagination({
        pageNumber: response.page.pageNumber,
        pageCount: response.page.pageCount,
        pageSize: response.page.pageSize,
      });

      return {
        data,
      };
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePagination = async (params: any) => {
    const prevState = trainings;
    const loadingElements = new Array(_defaultPagination.limit).fill(1);
    const order = sortingType === SortingType.MostUsed ? "most_popular" : "recently";
    const isLike = likeFilter === true ? 1 : 0;

    if (params.pageNumber > 1) {
      setIsLoading(true);
      setTrainings([...prevState, ...loadingElements]);
      try {
        const response = await trainService.getCreatedTrains({ page: params.pageNumber, limit: params.pageSize, query: searchQuery, order: order, is_like: isLike });

        setTrainings([...prevState, ...(response.data as any)]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  async function onDeleteTrain(trainId: string) {
    return await trainService.deleteTrain(trainId).then(() => fetchTrains());
  }

  async function onStartTrain(trainId: string) {
    return trainService.startTrain(trainId).then(() => fetchTrains());
  }

  async function onEditTrain(train_id: string, data: ITrainEditRequest) {
    return trainService.editTrain(train_id, data).then(() => setTrainings(trainings.map((item: any) => (item.id === train_id ? { ...item, name: data.name } : item))));
  }

  const contextValue: TrainingsContextType = {
    selectedTrainingType,
    setSelectedTrainingType,
    trainingTypes,
    setTrainingTypes,
    selectedTrainStatus,
    setSelectedTrainStatus,
    trainStatuses,
    setTrainStatuses,
    selectedAttributes,
    setSelectedAttributes,
    trainings,
    setTrainings,
    onDeleteTrain,
    onStartTrain,
    onEditTrain,
    setSortingType,
    pagination,
    isLoading,
    onChangePagination,
    likeFilter,
    setLikeFilter,
    sharedFilter,
    setSharedFilter,
    count,
    onSharedWithMePage,
    setSearchQuery,
    sortingType,
  };

  return <TrainingsContext.Provider value={contextValue}>{children}</TrainingsContext.Provider>;
};

export default TrainingsProvider;

export const useTrainingsContext = () => useContext(TrainingsContext);
