import React from "react";
import "./Tooltip.css";
import clsx from "clsx";

interface ITooltip {
  children: React.ReactNode;
  position?: "bottom" | "top" | "left" | "right" | null;
  content?: any;
  contentClassName?: string;
  zIndex?: number; //if two tooltips are intersected, use this to make one above the other
  className?: string;
}

const Tooltip = ({ children, position = "bottom", content = "", contentClassName, zIndex = 9999, className }: ITooltip) => {
  return (
    <div className={clsx("tooltip", position, className)} style={{ zIndex: zIndex }}>
      {children}
      {content !== "" && <div className={clsx("content", contentClassName)}>{content}</div>}
    </div>
  );
};

export default Tooltip;
