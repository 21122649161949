import React from "react";
import Button from "components/Button";
import Modal from "components/Modal";
import Select from "components/Select";
import Dropdown from "components/Dropdown";
import { IconChevronDown } from "icons";
import { useClickOutside } from "hooks/useClickOutside";
import clsx from "clsx";

const ShareMenu = ({ show, onClose }: any) => {
  const [showAddUserDropdown, setShowAddUserDropdown] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => {
    setShowAddUserDropdown(false);
  });

  //TODO shareden sonra snackbar firlat "Shared successfully!"

  return (
    <Modal onClose={onClose} show={show} bodyClassName="!rounded-[5px] !border-bg-3  no-scrollbar w-[420px]">
      <div className="flex px-2.5 py-3 bg-bg-3 rounded-t-[4px] text-h6 text-white">Share</div>
      <div className="flex flex-col gap-2.5 p-2.5 bg-bg w-full">
        <Select
          className="w-full"
          options={[
            { id: 0, title: "Share this with everyone at your team" },
            { id: 1, title: "Select members who you want to share" },
          ]}
          onChange={(val) => console.log(val)}
        />
        <div className="relative flex w-full">
          <div
            className={clsx("cursor-pointer flex w-full h-[42px] text-white border border-bg-3 rounded-[5px]", showAddUserDropdown ? "pointer-events-none" : "")}
            onClick={() => setShowAddUserDropdown(true)}
          >
            <span className="text-bodySm text-grey text-overflow py-3 px-2.5">Add Users</span>
            <div className="py-3 px-2.5 bg-bg border-l border-bg-3 rounded-r-[4px]">
              <IconChevronDown className="w-[18px] h-[18px]" />
            </div>
          </div>
          {showAddUserDropdown && (
            <div className="absolute top-0 left-0 mt-12 w-full" ref={dropdownRef}>
              <Dropdown />
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-2.5 p-2.5 bg-bg-2 border-t border-bg-3 rounded-b-[4px]">
        <Button className="btn-secondary w-full" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled className="btn-primary w-full">
          Share
        </Button>
      </div>
    </Modal>
  );
};

export default ShareMenu;
