import React from "react";
import Modal from "components/Modal";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { hideSnackbar } from "store/snackbarSlice";
import { useNavigate } from "react-router-dom";
import { AnalyticsEvent } from "utils";
import userService from "api/user/user.service";
import { IconCheck, IconClose } from "icons";
import BeforeAfterImage from "components/BeforeAfterImage";
import {
  ImagePaywall1,
  ImagePaywall2,
  ImagePaywall3,
  ImageDesignTools1,
  ImageDesignTools2,
  ImageDesignTools_Attribute1,
  ImageDesignTools_Attribute2,
  ImageDesignTools_Color1,
  ImageDesignTools_Color2,
  ImageDesignTools_Color3,
  ImageDesignTools_Color4,
  ImageDesignTools_Color5,
  ImageDesignTools_Color_Select1,
  ImageDesignTools_Color_Select2,
  ImageDesignTools_Color_Select3,
  ImageDesignTools_Color_Select4,
  ImageDesignTools_Fabric1,
  ImageDesignTools_Fabric2,
  ImageDesignTools_Fabric3,
  ImageDesignTools_Fabric4,
  ImageDesignTools_Fabric_Select1,
  ImageDesignTools_Fabric_Select2,
  ImageDesignTools_Fabric_Select3,
  ImageDesignTools_Pattern1,
  ImageDesignTools_Pattern2,
  ImageDesignTools_Pattern3,
  ImageDesignTools_Pattern4,
  ImageDesignTools_Pattern5,
  ImageDesignTools_Pattern6,
  ImageDesignTools_Pattern7,
  ImageDesignTools_Pattern_Select1,
  ImageDesignTools_Pattern_Select2,
  ImageDesignTools_Pattern_Select3,
  ImageDesignTools_Pattern_Select4,
  ImageDesignTools_Photoshoot1,
  ImageDesignTools_Photoshoot2,
  ImageDesignTools_Sketch1,
  ImageDesignTools_Sketch2,
  ImageDesignTools_Technical1,
  ImageDesignTools_Technical2,
  ImageDesignTools_Variations1,
  ImageDesignTools_Variations2,
  ImageDesignTools_Variations3,
  ImageDesignTools_Variations4,
  ImageAIPhotoshootWelcome1,
  ImageAIPhotoshootWelcome2,
  ImageAIPhotoshootWelcome3,
  ImageAIPhotoshootWelcome4,
} from "images";
import { Swiper, SwiperClass, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { nanoid } from "@reduxjs/toolkit";
import Button from "components/Button";
import { PATHS } from "router/config/paths";
import { EventDispatchSnackbar } from "router/Router";
import clsx from "clsx";

export enum ScreenType {
  Train,
  Team,
  Tryon,
  Design,
  Mannequin,
  Ghost,
}

export enum PackageType {
  Starter,
  Essentials,
  Premium,
  DesignStudio,
  Foundational,
  Prestige,
  NoPackage,
}

function getText(type: any) {
  switch (type) {
    case 402:
      return "Please upgrade your plan to use this feature.";
    case 409:
      return "Your design is currently being generated. Please wait until it’s complete before starting the next one.⏳";
    case 503:
      return "Our servers are currently too busy. Please try again later.";
    default:
      return "An error occurred.";
  }
}

const AnalyticsPanels = ["design", "new-training", "photoshoot", "account", "trends"];

const designStudioItems: {
  subtitle: string;
  items: { value: string; text: string }[];
} = {
  subtitle: "When you upgrade to Design Studio, you also get:",
  items: [
    { value: "3,000", text: "monthly design credits per seat" },
    { value: "750", text: "monthly AI+ credits per seat" },
    { value: "Fast Generation Speed", text: "" },
    { value: "Private Designs", text: "" },
  ],
};

const essentialsItems: {
  subtitle: string;
  items: { value: string; text: string }[];
} = {
  subtitle: "When you upgrade to Essentials, you also get:",
  items: [
    { value: "1,000", text: "monthly design credits" },
    { value: "250", text: "monthly AI+ credits" },
    { value: "250", text: "monthly Prompt Assistant credits" },
    { value: "Fast Generation Speed", text: "" },
    { value: "3 days free trial", text: "" },
  ],
};

const prestigeItems: {
  subtitle: string;
  items: { value: string; text: string }[];
} = {
  subtitle: "When you upgrade to Prestige, you also get:",
  items: [
    { value: "250", text: "monthly photoshoot credits" },
    { value: "250", text: "monthly design credits" },
    { value: "Mannequin Photoshoot", text: "" },
    { value: "Virtual Try-On", text: "" },
    { value: "Design Editing", text: "" },
  ],
};

const foundationalItems: {
  subtitle: string;
  items: { value: string; text: string }[];
} = {
  subtitle: "When you buy the Foundational, you also get:",
  items: [
    { value: "30", text: "monthly photoshoot credits" },
    { value: "Mannequin Photoshoot", text: "" },
  ],
};

const images = [ImageAIPhotoshootWelcome1, ImageAIPhotoshootWelcome2, ImageAIPhotoshootWelcome3, ImageAIPhotoshootWelcome4];

const INITIAL_SLIDE = 0;

function getScreenTypeText(screen: ScreenType) {
  switch (screen) {
    case ScreenType.Train:
      return "training";
    case ScreenType.Team:
      return "team";
    case ScreenType.Tryon:
      return "try-on";
    case ScreenType.Design:
      return "design";
    case ScreenType.Mannequin:
      return "mannequin";
    case ScreenType.Ghost:
      return "ghost";
    default:
      return "";
  }
}

const WarningSnackbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { visible, type, message, screen } = useAppSelector((state) => state.snackbar);
  const { user } = useAppSelector((state) => state.common);
  const _pathname = window.location.pathname;
  const clearedPathname = _pathname.split("/")[1];
  const [userPackageType, setUserPackageType] = React.useState<PackageType | null>(PackageType.Starter);
  const [activeIndex, setActiveIndex] = React.useState(INITIAL_SLIDE);

  const SwipeItems = [
    {
      render: () => (
        <div className="flex w-full rounded-md overflow-hidden">
          <BeforeAfterImage sliderPercentPosition={40} mainImage={ImageDesignTools_Sketch1} images={[ImageDesignTools_Sketch2]} />
        </div>
      ),
    },
    {
      render: () => (
        <div className="flex w-full rounded-md overflow-hidden">
          <BeforeAfterImage
            title="prints and graphics library"
            mainImage={ImageDesignTools_Pattern1}
            selectionImages={[ImageDesignTools_Pattern_Select1, ImageDesignTools_Pattern_Select2, ImageDesignTools_Pattern_Select3, ImageDesignTools_Pattern_Select4]}
            images={[ImageDesignTools_Pattern2, ImageDesignTools_Pattern3, ImageDesignTools_Pattern4, ImageDesignTools_Pattern5, ImageDesignTools_Pattern6, ImageDesignTools_Pattern7]}
          />
        </div>
      ),
    },
    {
      render: () => (
        <div className="flex w-full max-w-[440px]  rounded-md overflow-hidden aspect-[0.73]">
          <BeforeAfterImage
            title="Your fabrıc lıbrary"
            sliderPercentPosition={45}
            mainImage={ImageDesignTools_Fabric1}
            selectionImages={[ImageDesignTools_Fabric_Select1, ImageDesignTools_Fabric_Select2, ImageDesignTools_Fabric_Select3]}
            images={[ImageDesignTools_Fabric2, ImageDesignTools_Fabric3, ImageDesignTools_Fabric4]}
          />
        </div>
      ),
    },
    {
      render: () => (
        <div className="flex w-full max-w-[440px] rounded-md overflow-hidden aspect-[0.73]">
          <BeforeAfterImage
            title="COLOR lıbrary"
            sliderPercentPosition={45}
            mainImage={ImageDesignTools_Color1}
            selectionImages={[ImageDesignTools_Color_Select1, ImageDesignTools_Color_Select2, ImageDesignTools_Color_Select3, ImageDesignTools_Color_Select4]}
            images={[ImageDesignTools_Color2, ImageDesignTools_Color3, ImageDesignTools_Color4, ImageDesignTools_Color5]}
          />
        </div>
      ),
    },
    {
      render: () => (
        <div className="flex w-full max-w-[440px] rounded-md overflow-hidden aspect-[0.73]">
          <BeforeAfterImage mainImage={ImageDesignTools_Attribute1} images={[ImageDesignTools_Attribute2]} />
        </div>
      ),
    },
    {
      render: () => (
        <div className="flex w-full max-w-[440px] rounded-md overflow-hidden aspect-[0.73]">
          <BeforeAfterImage mainImage={ImageDesignTools_Technical1} images={[ImageDesignTools_Technical2]} />
        </div>
      ),
    },
  ];

  const paywallItems: { img: string; className?: string; title: string; render?: any; description: string; subtitle: string; items: { value: string; text: string }[] }[][] = [
    [
      //starter
      {
        img: ImagePaywall1,
        // render: () => {
        //   return (
        //     <div className="flex flex-col h-full gap-5">
        //       <Swiper
        //         slidesPerView={"auto"}
        //         centeredSlides={true}
        //         spaceBetween={15}
        //         // pagination={{
        //         //   clickable: true,
        //         // }}
        //         onSlideChange={(swiper: SwiperClass) => {
        //           setActiveIndex(swiper.activeIndex);
        //         }}
        //         modules={[Pagination]}
        //         initialSlide={INITIAL_SLIDE}
        //         allowTouchMove={false} // Disable touch/mouse dragging
        //         keyboard={false} // Disable keyboard controls
        //         simulateTouch={false} // Disable simulated touch events
        //         touchStartPreventDefault={false}
        //       >
        //         {SwipeItems.map((item) => (
        //           <SwiperSlide key={nanoid()}>
        //             <div className="flex flex-1">{item.render()}</div>
        //           </SwiperSlide>
        //         ))}
        //       </Swiper>

        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to access the Training feature and get personalized recommendations based on your brand's unique DNA.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall2,
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to enable seamless team collaboration and bring your ideas to life together.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall3,
        title: "Upgrade to Design Studio",
        className: "max-h-[540px] max-w-[655px] w-full h-full aspect-[1.2]",
        description: "Upgrade to Design Studio to use the Try-on feature and see your designs easily on realistic AI models.",
        ...designStudioItems,
      },
      {
        img: "",
        title: "",
        description: "",
        ...designStudioItems,
      },
      {
        img: "",
        render: () => {
          const [selectedIndex, setSelectedIndex] = React.useState(0);

          return (
            <div className="relative">
              <div className="absolute bottom-10 left-1/2 w-[294px] transform -translate-x-1/2 bg-green rounded-xl p-3">
                <div className="flex flex-col w-full gap-2">
                  <span className="text-[13px] font-satoshi uppercase font-bold">model</span>
                  <div className="flex w-full gap-2.5">
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        className={`w-[60px] h-[60px] aspect-square object-cover rounded-xl cursor-pointer p-1 ${selectedIndex === index ? "border-2 border-black" : ""}`}
                        onClick={() => setSelectedIndex(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <img src={images[selectedIndex]} className="w-full max-h-[600px] rounded-xl" />
            </div>
          );
        },
        title: "Buy the Foundational",
        description: "Buy the Foundational package to access Mannequin Photoshooting and create professional shots with diverse models for your brand.",
        ...foundationalItems,
      },
      {
        img: "",
        render: () => {
          const [selectedIndex, setSelectedIndex] = React.useState(0);

          return (
            <div className="relative">
              <div className="absolute bottom-10 left-1/2 w-[294px] transform -translate-x-1/2 bg-green rounded-xl p-3">
                <div className="flex flex-col w-full gap-2">
                  <span className="text-[13px] font-satoshi uppercase font-bold">model</span>
                  <div className="flex w-full gap-2.5">
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        className={`w-[60px] h-[60px] aspect-square object-cover rounded-xl cursor-pointer p-1 ${selectedIndex === index ? "border-2 border-black" : ""}`}
                        onClick={() => setSelectedIndex(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <img src={images[selectedIndex]} className="w-full max-h-[600px] rounded-xl" />
            </div>
          );
        },
        title: "Buy the Foundational",
        description: "Buy the Foundational package to access Mannequin Photoshooting and create professional shots with diverse models for your brand.",
        ...foundationalItems,
      },
    ],
    [
      //essentials
      {
        img: ImagePaywall1,
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to access the Training feature and get personalized recommendations based on your brand's unique DNA.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall2,
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to enable seamless team collaboration and bring your ideas to life together.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall3,
        className: "max-h-[540px] max-w-[655px] w-full h-full aspect-[1.2]",
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to use the Try-on feature and see your designs easily on realistic AI models.",
        ...designStudioItems,
      },
      {
        img: "",
        title: "",
        description: "",
        ...designStudioItems,
      },
      {
        img: "",
        render: () => {
          const [selectedIndex, setSelectedIndex] = React.useState(0);

          return (
            <div className="relative">
              <div className="absolute bottom-10 left-1/2 w-[294px] transform -translate-x-1/2 bg-green rounded-xl p-3">
                <div className="flex flex-col w-full gap-2">
                  <span className="text-[13px] font-satoshi uppercase font-bold">model</span>
                  <div className="flex w-full gap-2.5">
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        className={`w-[60px] h-[60px] aspect-square object-cover rounded-xl cursor-pointer p-1 ${selectedIndex === index ? "border-2 border-black" : ""}`}
                        onClick={() => setSelectedIndex(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <img src={images[selectedIndex]} className="w-full max-h-[600px] rounded-xl" />
            </div>
          );
        },
        title: "Buy the Foundational",
        description: "Buy the Foundational package to access Mannequin Photoshooting and create professional shots with diverse models for your brand.",
        ...foundationalItems,
      },
      {
        img: "",
        render: () => {
          const [selectedIndex, setSelectedIndex] = React.useState(0);

          return (
            <div className="relative">
              <div className="absolute bottom-10 left-1/2 w-[294px] transform -translate-x-1/2 bg-green rounded-xl p-3">
                <div className="flex flex-col w-full gap-2">
                  <span className="text-[13px] font-satoshi uppercase font-bold">model</span>
                  <div className="flex w-full gap-2.5">
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        className={`w-[60px] h-[60px] aspect-square object-cover rounded-xl cursor-pointer p-1 ${selectedIndex === index ? "border-2 border-black" : ""}`}
                        onClick={() => setSelectedIndex(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <img src={images[selectedIndex]} className="w-full max-h-[600px] rounded-xl" />
            </div>
          );
        },
        title: "Buy the Foundational",
        description: "Buy the Foundational package to access Mannequin Photoshooting and create professional shots with diverse models for your brand.",
        ...foundationalItems,
      },
    ],
    [
      //premium
      {
        img: ImagePaywall1,
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to access the Training feature and get personalized recommendations based on your brand's unique DNA.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall2,
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to enable seamless team collaboration and bring your ideas to life together.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall3,
        title: "Upgrade to Design Studio",
        className: "max-h-[540px] max-w-[655px] w-full h-full aspect-[1.2]",
        description: "Upgrade to Design Studio to use the Try-on feature and see your designs easily on realistic AI models.",
        ...designStudioItems,
      },
      {
        img: "",
        title: "",
        description: "",
        ...designStudioItems,
      },
      {
        img: "",
        render: () => {
          const [selectedIndex, setSelectedIndex] = React.useState(0);

          return (
            <div className="relative">
              <div className="absolute bottom-10 left-1/2 w-[294px] transform -translate-x-1/2 bg-green rounded-xl p-3">
                <div className="flex flex-col w-full gap-2">
                  <span className="text-[13px] font-satoshi uppercase font-bold">model</span>
                  <div className="flex w-full gap-2.5">
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        className={`w-[60px] h-[60px] aspect-square object-cover rounded-xl cursor-pointer p-1 ${selectedIndex === index ? "border-2 border-black" : ""}`}
                        onClick={() => setSelectedIndex(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <img src={images[selectedIndex]} className="w-full max-h-[600px] rounded-xl" />
            </div>
          );
        },
        title: "Buy the Foundational",
        description: "Buy the Foundational package to access Mannequin Photoshooting and create professional shots with diverse models for your brand.",
        ...foundationalItems,
      },
      {
        img: "",
        render: () => {
          const [selectedIndex, setSelectedIndex] = React.useState(0);

          return (
            <div className="relative">
              <div className="absolute bottom-10 left-1/2 w-[294px] transform -translate-x-1/2 bg-green rounded-xl p-3">
                <div className="flex flex-col w-full gap-2">
                  <span className="text-[13px] font-satoshi uppercase font-bold">model</span>
                  <div className="flex w-full gap-2.5">
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        className={`w-[60px] h-[60px] aspect-square object-cover rounded-xl cursor-pointer p-1 ${selectedIndex === index ? "border-2 border-black" : ""}`}
                        onClick={() => setSelectedIndex(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <img src={images[selectedIndex]} className="w-full max-h-[600px] rounded-xl" />
            </div>
          );
        },
        title: "Buy the Foundational",
        description: "Buy the Foundational package to access Mannequin Photoshooting and create professional shots with diverse models for your brand.",
        ...foundationalItems,
      },
    ],
    [
      //design studio
      {
        img: ImagePaywall1,
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to access the Training feature and get personalized recommendations based on your brand's unique DNA.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall2,
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to enable seamless team collaboration and bring your ideas to life together.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall3,
        title: "Upgrade to Design Studio",
        className: "max-h-[540px] max-w-[655px] w-full h-full aspect-[1.2]",
        description: "Upgrade to Design Studio to use the Try-on feature and see your designs easily on realistic AI models.",
        ...designStudioItems,
      },
      {
        img: "",
        title: "",
        description: "",
        ...designStudioItems,
      },
      {
        img: "",
        render: () => {
          const [selectedIndex, setSelectedIndex] = React.useState(0);

          return (
            <div className="relative">
              <div className="absolute bottom-10 left-1/2 w-[294px] transform -translate-x-1/2 bg-green rounded-xl p-3">
                <div className="flex flex-col w-full gap-2">
                  <span className="text-[13px] font-satoshi uppercase font-bold">model</span>
                  <div className="flex w-full gap-2.5">
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        className={`w-[60px] h-[60px] aspect-square object-cover rounded-xl cursor-pointer p-1 ${selectedIndex === index ? "border-2 border-black" : ""}`}
                        onClick={() => setSelectedIndex(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <img src={images[selectedIndex]} className="w-full max-h-[600px] rounded-xl" />
            </div>
          );
        },
        title: "Buy the Foundational",
        description: "Buy the Foundational package to access Mannequin Photoshooting and create professional shots with diverse models for your brand.",
        ...foundationalItems,
      },
      {
        img: "",
        render: () => {
          const [selectedIndex, setSelectedIndex] = React.useState(0);

          return (
            <div className="relative">
              <div className="absolute bottom-10 left-1/2 w-[294px] transform -translate-x-1/2 bg-green rounded-xl p-3">
                <div className="flex flex-col w-full gap-2">
                  <span className="text-[13px] font-satoshi uppercase font-bold">model</span>
                  <div className="flex w-full gap-2.5">
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        className={`w-[60px] h-[60px] aspect-square object-cover rounded-xl cursor-pointer p-1 ${selectedIndex === index ? "border-2 border-black" : ""}`}
                        onClick={() => setSelectedIndex(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <img src={images[selectedIndex]} className="w-full max-h-[600px] rounded-xl" />
            </div>
          );
        },
        title: "Buy the Foundational",
        description: "Buy the Foundational package to access Mannequin Photoshooting and create professional shots with diverse models for your brand.",
        ...foundationalItems,
      },
    ],
    [
      //foundational
      {
        img: ImagePaywall1,
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to access the Training feature and get personalized recommendations based on your brand's unique DNA.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall2,
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to enable seamless team collaboration and bring your ideas to life together.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall3,
        title: "Upgrade to Design Studio",
        className: "max-h-[540px] max-w-[655px] w-full h-full aspect-[1.2]",
        description: "Upgrade to Design Studio to use the Try-on feature and see your designs easily on realistic AI models.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall3,
        render: () => {
          return (
            <div className="flex flex-col h-full gap-5">
              <Swiper
                slidesPerView={"auto"}
                centeredSlides={true}
                spaceBetween={15}
                // pagination={{
                //   clickable: true,
                // }}
                onSlideChange={(swiper: SwiperClass) => {
                  setActiveIndex(swiper.activeIndex);
                }}
                modules={[Pagination]}
                initialSlide={INITIAL_SLIDE}
                allowTouchMove={false} // Disable touch/mouse dragging
                keyboard={false} // Disable keyboard controls
                simulateTouch={false} // Disable simulated touch events
                touchStartPreventDefault={false}
              >
                {SwipeItems.map((item) => (
                  <SwiperSlide key={nanoid()}>
                    <div className="flex flex-1 !max-h-[540px]">{item.render()}</div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          );
        },
        title: "Upgrade to Prestige",
        description: "Upgrade to Prestige to unlock advanced photo editing tools and create stunning professional images.",
        ...prestigeItems,
      },
    ],
    [
      //prestige
      {
        img: ImagePaywall1,
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to access the Training feature and get personalized recommendations based on your brand's unique DNA.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall2,
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to enable seamless team collaboration and bring your ideas to life together.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall3,
        title: "Upgrade to Design Studio",
        className: "max-h-[540px] max-w-[655px] w-full h-full aspect-[1.2]",
        description: "Upgrade to Design Studio to use the Try-on feature and see your designs easily on realistic AI models.",
        ...designStudioItems,
      },
    ],
    [
      //no package
      {
        img: ImagePaywall1,

        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to access the Training feature and get personalized recommendations based on your brand's unique DNA.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall2,
        title: "Upgrade to Design Studio",
        description: "Upgrade to Design Studio to enable seamless team collaboration and bring your ideas to life together.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall3,
        title: "Upgrade to Design Studio",
        className: "max-h-[540px] max-w-[655px] w-full h-full aspect-[1.2]",
        description: "Upgrade to Design Studio to use the Try-on feature and see your designs easily on realistic AI models.",
        ...designStudioItems,
      },
      {
        img: ImagePaywall3,
        render: () => (
          <div className="flex h-full w-full rounded-md overflow-hidden">
            <BeforeAfterImage sliderPercentPosition={40} mainImage={ImageDesignTools_Sketch1} images={[ImageDesignTools_Sketch2]} />
          </div>
        ),
        title: "Upgrade to Essentials",
        description: "Upgrade to Essentials to unlock advanced design features, powerful editing tools, and embark on your journey to creativity.",
        ...essentialsItems,
      },
    ],
  ];

  React.useEffect(() => {
    if (!user.id || !visible) return;

    userService.getUserDetail({ user_id: user.id }).then((res) => {
      switch (res.responseData.package_detail.name.toLowerCase()) {
        case "starter":
          setUserPackageType(PackageType.Starter);
          break;
        case "essentials":
          setUserPackageType(PackageType.Essentials);
          break;
        case "premium":
          setUserPackageType(PackageType.Premium);
          break;
        case "design studio":
          setUserPackageType(PackageType.DesignStudio);
          break;
        case "foundational":
          setUserPackageType(PackageType.Foundational);
          break;
        case "prestige":
          setUserPackageType(PackageType.Prestige);
          break;
        case "no package":
          setUserPackageType(PackageType.NoPackage);
          break;
        default:
          break;
      }
    });
  }, [user.id]);

  React.useEffect(() => {
    if (visible && AnalyticsPanels.includes(clearedPathname) && type === 402) {
      AnalyticsEvent("Upgrade_Plan_Pop-up", "", "pop-up shown", { panel: getScreenTypeText(screen) });
    }
  }, [visible]);

  const handleClose = () => {
    dispatch(hideSnackbar());
  };

  if (!visible || userPackageType === null) return null;

  console.log(userPackageType, screen);

  return (
    <Modal
      onClose={handleClose}
      show={true}
      bodyClassName={clsx("bg-bg-2 !max-h-[600px] !max-w-[920px]", screen === ScreenType.Tryon ? "!max-w-[1180px]" : "")}
      className="!z-[9999] "
      backdropDisabled={true}
    >
      {type !== 402 && <IconClose className="absolute cursor-pointer w-6 h-6 text-grey top-2 left-2" onClick={handleClose} />}

      <div className="flex h-full gap-10 p-6 w-fit">
        {type === 402 ? (
          <>
            <div className="flex h-full w-full">
              {paywallItems[userPackageType][screen]?.render ? (
                <>{paywallItems[userPackageType][screen].render()}</>
              ) : (
                <img src={paywallItems[userPackageType][screen]?.img} className={clsx("h-full object-contain", paywallItems[userPackageType][screen]?.className ?? "")} />
              )}
            </div>
            <div className="flex flex-col w-full justify-between max-w-[440px]">
              <div className="flex flex-col w-full gap-10 text-white">
                <h2 className="text-h2">{paywallItems[userPackageType][screen]?.title}</h2>
                <span className="text-[17px] leading-[140%] tracking-[-0.255px] font-workSans">{paywallItems[userPackageType][screen]?.description}</span>
                <div className="flex flex-col gap-3">
                  <span className="text-[15px] leading-[140%] tracking-[-0.255px] font-workSans font-semibold">{paywallItems[userPackageType][screen]?.subtitle}</span>
                  <ul className="flex flex-col gap-3">
                    {paywallItems[userPackageType][screen]?.items.map((item, index) => (
                      <li key={index}>
                        <span className="flex items-center gap-[5px] text-h6 !font-normal">
                          <IconCheck className="w-5 h-5 text-green" />
                          <b className="!font-semibold">{item.value}</b> {item.text}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  className="btn-secondary w-full"
                  onClick={() => {
                    handleClose();
                    navigate(PATHS.HOME);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-primary w-full"
                  onClick={() => {
                    handleClose();
                    navigate(PATHS.PRICING);
                    AnalyticsEvent("Upgrade_Plan", "", "click", { panel: getScreenTypeText(screen) });
                  }}
                >
                  Upgrade Now
                </Button>
              </div>
            </div>
          </>
        ) : (
          <h2 className="text-h2 text-white text-center max-w-[650px]">{message ? message : getText(type)}</h2>
        )}
      </div>
    </Modal>
  );
};

export default WarningSnackbar;
