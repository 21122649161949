import clsx from "clsx";
import Button from "components/Button";
import CheckBox from "components/CheckBox";
import Tooltip from "components/Tooltip";
import { useClickOutside } from "hooks/useClickOutside";
import { IconChevronDown, IconDownload, IconImage } from "icons";
import React, { useRef, useState } from "react";
import Dropdown from "./components/Dropdown";
import designService from "api/design/design.service";
import Lottie from "lottie-react";
import lottieData from "../../animations/loading-spinner.json";
import { DOWNLOAD_SETTING, useLocalStorage } from "hooks/useLocalStorage";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/commonSlice";

const options = [
  {
    value: "png",
    icon: IconImage,
    text: "PNG",
    description: "Best for photos",
  },
  {
    value: "jpg",
    icon: IconImage,
    text: "JPG",
    description: "Best for sharing",
  },
  // {
  //   value: "svg",
  //   icon: IconPdf,
  //   text: "SVG",
  //   description: "Best for printing",
  // },
];

const getFilenameFromUrl = (url: string, file_type: string) => {
  const match = url.match(/\/([^/?#]+)[^/]*$/);
  if (match) {
    return match[1];
  }

  return "image." + file_type;
};

const DownloadMenu = ({ fileType, setFileType, onDownload, isDownloading }: any) => {
  const [isSaved, setIsSaved] = useState(useLocalStorage().getItem(DOWNLOAD_SETTING) === fileType.value);

  const renderItem = (item: any) => {
    const Icon = item.icon;
    const isActive = item.value === fileType.value;

    return (
      <li key={item.value} className={clsx("flex item-center cursor-pointer p-[7px] border border-bg-3 rounded", isActive ? "bg-green bg-opacity-10" : "bg-bg-2")}>
        <div
          className="flex items-center w-full gap-2.5"
          onClick={() => {
            setFileType(item);
            if (isSaved) useLocalStorage().setItem(DOWNLOAD_SETTING, item.value);
          }}
        >
          <div className={clsx("rounded-full h-2.5 w-2.5", isActive ? "bg-green" : "bg-bg-3")} />
          <Icon className="text-white w-4 h-4" />
          <span className="text-white text-bodySm">{item.text}</span>
          <span className="text-grey text-[11px] font-normal font-workSans leading-[15.4px] tracking-[-0.165px]">{item.description}</span>
        </div>
      </li>
    );
  };

  return (
    <div className="flex flex-col rounded-[5px] bg-bg border border-bg-3">
      <div className="flex gap-[5px] text-white p-2 bg-bg-3 rounded-t-[4px]">
        <IconDownload className="w-[14px] h-[14px]" />
        <span className="text-headline uppercase">download</span>
      </div>

      <div className="flex flex-col p-2.5 gap-2.5">
        <span className="text-h7 text-white">File format</span>

        <Dropdown options={options} isToggle={true} renderItem={renderItem} hideBorder={true} className="w-full gap-1 p-[7px]">
          <div className="cursor-pointer flex border border-bg-3 rounded w-full ">
            <div className="flex items-center gap-2.5 w-full p-[7px]">
              <fileType.icon className="text-white w-4 h-4" />
              <span className="text-bodySm text-white">{fileType.text}</span>
            </div>
            <div className="flex-center border-l border-bg-3 p-[7px]">
              <IconChevronDown className="flex-shrink-0 w-[18px] h-[18px] text-white" />
            </div>
          </div>
        </Dropdown>

        <div className="flex w-full h-[1px] bg-bg-3" />
        <CheckBox
          checked={isSaved}
          checkedColor="bg-green"
          containerClassName="cursor-pointer flex items-center gap-2.5 w-fit"
          onChange={(e: any, isChecked: boolean) => {
            setIsSaved(isChecked);
            if (isChecked) useLocalStorage().setItem(DOWNLOAD_SETTING, fileType.value);
            else useLocalStorage().removeItem(DOWNLOAD_SETTING);
          }}
        >
          <span className="text-bodySm text-white">Save download settings</span>
        </CheckBox>
      </div>

      <div className="flex-center border-t border-bg-3 p-2.5 bg-bg-2 rounded-b-[4px]">
        {isDownloading ? (
          <Lottie animationData={lottieData} loop={true} className="w-8 h-8" />
        ) : (
          <Button
            className="btn-primary btn-sm w-full"
            onClick={() => {
              onDownload();
            }}
          >
            Download
          </Button>
        )}
      </div>
    </div>
  );
};

const DownloadButton = ({ isDisplayed, imageId, designId, isImageLandscape, position, imageURL }: any) => {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const downloadButtonRef = useRef<HTMLDivElement>(null);
  const [fileType, setFileType] = useState(options.find((option) => option.value === useLocalStorage().getItem(DOWNLOAD_SETTING)) || options[0]);
  const [isDownloading, setIsDownloading] = useState(false);

  function calculateDistanceToRight(ref: any) {
    if (ref.current) return window.innerWidth - ref.current.getBoundingClientRect().right;

    return 0;
  }

  useClickOutside(menuRef, () => {
    setShowMenu(false);
  });

  React.useEffect(() => {
    if (!isDisplayed) setShowMenu(false);
  }, [isDisplayed]);

  async function download() {
    if (isDownloading) return;

    if (imageURL) {
      const filename = getFilenameFromUrl(imageURL, fileType.value);

      fetch(imageURL)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.setAttribute("download", filename);
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(url); // Clean up the object URL
          document.body.removeChild(a);
        })
        .catch(console.error);

      return;
    }

    try {
      setIsDownloading(true);
      const res = await designService.download({
        design_id: designId,
        image_id: imageId,
        file_type: fileType.value,
      });

      const imageURL = res.img_url;

      const response = await fetch(imageURL);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const filename = getFilenameFromUrl(imageURL, fileType.value);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename; // Set the filename dynamically
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      console.error("Error downloading image:", error);
      dispatch(setSnackbar({ message: "Error downloading image", type: "error" }));
    }
  }

  return (
    <div className="relative" ref={downloadButtonRef}>
      <Tooltip content="Download" position={position ? position : isImageLandscape ? "top" : "left"}>
        <Button
          className={clsx("bg-white p-2 hover:bg-green w-[26px] h-[26px] rounded-[3px]", showMenu ? "!bg-green pointer-events-none" : "bg-white")}
          onClick={() => {
            if (imageURL) {
              download();
            } else setShowMenu(true);
          }}
        >
          <IconDownload className="w-4 h-4 flex-shrink-0" />
        </Button>
      </Tooltip>

      {showMenu && (
        <div ref={menuRef}>
          <div
            className={clsx("absolute z-50 w-[270px] ", position === "left" ? "!-left-[275px] top-0" : isImageLandscape ? "top-8 !right-0" : "top-0 right-full")}
            style={{ left: calculateDistanceToRight(downloadButtonRef) > 290 ? "calc(100% + 6px)" : "", right: calculateDistanceToRight(downloadButtonRef) > 290 ? "" : "calc(100% + 6px)" }}
          >
            <DownloadMenu setFileType={setFileType} fileType={fileType} onDownload={download} isDownloading={isDownloading} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadButton;

//"right-12 top-0"
