import React, { useEffect, useRef, useState, useCallback } from "react";
import { ImageFallback } from "images";

const LazyImg = ({ src, onError, fallbackImg, staticImage, ...rest }: any) => {
  const ref = useRef<HTMLImageElement>(null);
  const [hasError, setHasError] = useState(false);

  const handleError = useCallback(() => {
    if (!hasError) {
      onError?.();
      setHasError(true);
    }
  }, [onError, hasError]);

  useEffect(() => {
    setHasError(false);
  }, [src]);

  useEffect(() => {
    const currentRef = ref.current;
    if (!currentRef) return;

    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry?.isIntersecting) {
        observer.disconnect();
      }
    });

    observer.observe(currentRef);

    return () => observer.disconnect();
  }, [src]);

  const imageSrc = hasError ? fallbackImg ?? ImageFallback : src;

  return <img {...rest} src={staticImage ? staticImage : imageSrc} ref={ref} draggable={false} onError={handleError} />;
};

export default React.memo(LazyImg);
